
import { Box, Container, createTheme, ThemeProvider, Typography, Link as P, Button } from "@mui/material";
import React, { useEffect, useState } from "react"


import { Helmet } from "react-helmet";

import './App.css';
import CodeConainer from "./components/Code/CodeContainer";
import Navbar from "./core/Navbar";
import History from "./pages/History";
import { useTranslation } from "react-i18next";



import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,

  useRoutes,
  useNavigate,
  Outlet
} from "react-router-dom";
import Main from "./pages/Main";
import NewCode from "./pages/NewCode";
import Friends from "./pages/Friends";
import Support from "./pages/Support";
import Tariff from "./components/Tariff/Tariff";
import TariffInfo from "./pages/TariffInfo";
import PayList from "./components/Balance/PayList";
import PayTemp from "./components/Temp/PayTemp";
import SubscriptionDescription from "./components/New/SubscriptionDescription";
import PayListtFirst from "./components/Balance/PayListtFirst";
import PayFirst from "./pages_v2/PayFirst";
import Help from "./pages/Help";
import CheckCreation from "./pages/CheckCreation";
import EditCode from "./components/Connection/EditCode";
import Login from "./components/Auth/LoginForm";
import { Provider } from "react-redux";
import PayRefresh from "./components/Temp/PayRefresh";
import TariffInfo_v2 from "./components/Tariff/TariffInfo_v2";
import FirstCreate from "./components/New/FirstCreate";
import Instructions from "./components/Instructions/Instructions";
/* import Test from "./components/test/Test"; */
import PayDetail from "./components/Balance/PayDetail";
import Snowfall from "react-snowfall";
import UserInfoForm from "./components/Auth/UserInfoForm";
import SendingMessage from "./utils/SendingMessage";
import Feedback from "./pages/Feedback";
import FeedbackResult from "./pages/FeedbackResult";
import BottomNavbar from "./core/BottomNavbar";
import RulePage_1 from "./pages_v2/RulePage_1";
import RulePage_2 from "./pages_v2/RulePage_2";
import RulePage_3 from "./pages_v2/RulePage_3";
import RulePage_4 from "./pages_v2/RulePage_4";
import RulePage_5 from "./pages_v2/RulePage_5";
import SetUpPage from "./pages_v2/SetUpPage";
import NewUserMain from "./pages_v2/NewUserMain";
import ResultPage from "./pages_v2/ResultPage";
import NavList from "./pages_v2/NavList";
import WhatIsWP from "./pages/WhatIsWP";
import RulePage_6 from "./pages_v2/RulePage_6";
import { motion } from "framer-motion";
import { Height } from "@mui/icons-material";
import PayDetailPage from "./pages_v2/PayDetailPage";
import MainApp from "./pages/MainApp";
import store from "./redux/store";
import { secondary_bg_color, themeTelegram } from "./themes/themeTelegram";
import ErrorPage from "./pages/ErrorPage";

/* import { useAppDispatch } from "./hooks/useAppDispatch";
 */

function WithNavbar(props: any) {
  const variants = {
    open: { opacity: 1, x: 0 },
    closed: { opacity: 0, x: "-100%" },
  }
  return (
    <div>

      <BottomNavbar tg={props.tg}></BottomNavbar>
      <BottomNavbar tg={props.tg}></BottomNavbar>
      <Box sx={{ mb: 8 }}>
        <Outlet />
      </Box>
    </div>

  )
}

function Test() {
  return (
    <Box sx={{ height: '100vh' }}>
      <Outlet />
    </Box>
  )
}
function AppRoutes(props: any) {

  const routes = useRoutes([
    {
      element: <WithNavbar tg={props.tg} />,
      children: [
        /*    { path: '/', element: <TagListContainer /> }, */
        { path: '/main', element: (<Main tg={props.tg} />) },


       
        { path: '/tariff', element: <TariffInfo tg={props.tg} /> },
        { path: '/pay', element: <PayTemp tg={props.tg} /> },

        { path: '/sub_desc', element: <SubscriptionDescription tg={props.tg} /> },


        { path: '/first', element: <FirstCreate tg={props.tg} /> },
        { path: '/help', element: <Help tg={props.tg} /> },
        
        { path: '/login', element: <Login /> },

        { path: '/instructions', element: <Instructions tg={props.tg} /> },


        { path: '/sendig_message', element: <SendingMessage tg={props.tg} /> },
        //  { path: '/main', element: <Main tg={props.tg} /> },
        { path: '/nav_list', element: <NavList tg={props.tg} /> },
        
        { path: '/friends', element: <Friends tg={props.tg} /> },


      ]
    },
    {
      element: <Test />,
      children: [
        { path: '/history', element: <History tg={props.tg} /> },
        { path: '/user_info', element: <UserInfoForm tg={props.tg} /> },
        { path: '/feedback', element: <Feedback tg={props.tg} /> },
        { path: '/feedback_result', element: <FeedbackResult tg={props.tg} /> }, 

        { path: '/support', element: <Support tg={props.tg} /> },
        { path: '/error', element: <ErrorPage tg={props.tg} /> },

        { path: '/rule_page_1', element: <RulePage_1 tg={props.tg} /> },
        { path: '/rule_page_2', element: <RulePage_2 tg={props.tg} /> },
        { path: '/rule_page_3', element: <RulePage_3 tg={props.tg} /> },
        { path: '/rule_page_4', element: <RulePage_4 tg={props.tg} /> },
        { path: '/rule_page_5', element: <RulePage_5 tg={props.tg} /> },
        { path: '/rule_page_6', element: <RulePage_6 tg={props.tg} /> },

        { path: '/set_up', element: <SetUpPage tg={props.tg} /> },
        { path: '/greetings', element: <NewUserMain tg={props.tg} /> },
        { path: '/result', element: <ResultPage tg={props.tg} /> },
        { path: '/', element: <MainApp tg={props.tg} /> },
        { path: '/what_is_wp', element: <WhatIsWP tg={props.tg} /> },
        { path: '/pay_first', element: <PayFirst tg={props.tg} /> },
        { path: '/pay_detail_first', element: <PayDetailPage tg={props.tg} /> },
        { path: '/new', element: <NewCode tg={props.tg} /> },
        { path: '/check', element: <CheckCreation tg={props.tg} /> },
        { path: '/tariff_desc', element: <TariffInfo_v2 tg={props.tg} /> },
        { path: '/pay_detail', element: <PayDetail tg={props.tg} /> },
        { path: '/edit', element: <EditCode tg={props.tg} /> },
        { path: '/pay_refresh', element: <PayRefresh tg={props.tg} /> },

      ]
    },
  ]);

  return routes;
}

let tg = (window as any).Telegram.WebApp;


function App() {

  const { t, i18n } = useTranslation();


  const navigate = useNavigate();

  var snowflake1 = new Image();
  snowflake1.src = 'https://whypn.com/snow.png'


  const images = [snowflake1]

  /*   const dispatch = useAppDispatch();
   */
  //tg.MainButton.color = "#D79900"
  tg.setHeaderColor(secondary_bg_color)
  /* tg.BackButton.show() */




  useEffect(() => {
    tg.expand()
    localStorage.setItem("token", tg)

    var start_param = tg?.initDataUnsafe?.start_param
    if (start_param != undefined) {
      navigate("/" + start_param)
    }
    /*   else {
        navigate("/")
      } */
    /*   let initDataURLSP = new URLSearchParams(tg.initData);
      var hash = initDataURLSP.get('hash');
      var checkDataString = initDataURLSP.toString()?.replaceAll('&', '\n');
  
   
          const param = encodeURI("http://localhost:5010" + url + "?hash=" + hash + "&checkDataString="+checkDataString+"&" )
          localStorage.setItem('token', JSON.stringify(param));
          dispatch(setToken(param)); */

  }, [tg]);


  const theme = createTheme({})

  var onHelpClick = () => {
    tg.openTelegramLink("https://t.me/+U2OF7AMSjtxmMWZi")
  }

  return (

    <Provider store={store}>



      <Helmet>
        {/* <style>{'body { background-color: ' + (tg != null ? (tg.colorScheme == 'dark' ? tg.themeParams.bg_color : '#edeef0') : '#edeef0') + '; }'}</style> */}

        {/*         <style>{'body { background-color: ' + (tg != null ? (tg.colorScheme == 'dark' ? tg.themeParams.bg_color : 'white') : 'white') + '; }'}</style>
 */}

        <style>{'body { background-color: ' + secondary_bg_color + '; }'}</style>
      </Helmet>

      <ThemeProvider theme={themeTelegram}>

        <Provider store={store}>
          {/* <Navbar tg={tg}></Navbar> */}

          <Container maxWidth="lg" >
            {/* 
            <Box sx={{ p: 2, background: tg != undefined && tg.colorScheme == 'dark' ? 'rgb(255 255 255 / 8%)' : 'white', borderRadius: '12px', textAlign: 'center' }} className='test_2' >
              <Typography variant="h5" sx={{ color: tg.themeParams.text_color ?? 'black', mt: 1 }} >У нас технические работы</Typography>
              <Typography variant="subtitle2" sx={{ color: tg.themeParams.text_color ?? 'black', mt: 1 }} >Ваш баланс в порядке, подключения в безопасности,</Typography>
              <Typography variant="subtitle2" sx={{ color: tg.themeParams.text_color ?? 'black', mt: 1, mb: 2 }} >Личный кабинет скоро будет доступен для работы</Typography>


              <Button   variant="contained" aria-label="add to shopping cart" sx={{ color: 'white', borderRadius: 0, backgroundColor: '#ffc107', p: 1, width: '100%' }}
                style={{ borderRadius: '10px', textTransform: 'none', boxShadow: 'none' }} onClick={onHelpClick}>
                Подробности в нашем блоге
            
              </Button >
            </Box> */}
            <AppRoutes tg={tg}>

            </AppRoutes>

          </Container>

        </Provider>
      </ThemeProvider>

    </Provider>


  );
}

export default App;
