import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, Grid, IconButton, LinearProgress, LinearProgressProps, Link, Skeleton, Typography } from '@mui/material';
import CopyToClipboard from 'react-copy-to-clipboard';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import RefreshIcon from '@mui/icons-material/Refresh';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteDialog from '../Dialogs/DeleteDialog';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import Moment from 'moment';
import AndroidIcon from '@mui/icons-material/Android';
import AppleIcon from '@mui/icons-material/Apple';
import LaptopWindowsIcon from '@mui/icons-material/LaptopWindows';
import HelpIcon from '@mui/icons-material/Help';
import { useTranslation } from 'react-i18next';
import { g_language } from '../../../core/UrlService';

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant="determinate" style={{ background: 'rgb(239, 239, 239)' }}  {...props} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" /* color="text.secondary" */>{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}


export default function WgConnection(props: any) {

    const {t, i18n} = useTranslation ()
    React.useEffect(()=>{
        i18n.changeLanguage(props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.language_code : g_language);
    },[])

    var a = Moment(props.item.Date)
    const formatDate = Moment(props.item.Date).utcOffset('GMT-00:00').format('DD.MM.YYYY HH:mm')


    const navigate = useNavigate();

    const [isOpen, setIsOpen] = React.useState(false);

    const [state, setState] = React.useState<any>({
        value: '',
        copied: false,
    })
    const [progress, setProgress] = React.useState(70);



    var getAvatar = (id: any) => {
        if (id == "Android") {
            // return "🤝"
            return (
                <div style={{ marginRight: 5 }}>
                    <AndroidIcon />
                </div>
            )
        }
        if (id == "IPhone") {
            //return "🌐"
            return (
                <div style={{ marginRight: 5 }}>
                    <AppleIcon /></div>

            )
        }
        if (id == "Windows") {
            return (
                <div style={{ marginRight: 5 }}>
                    <LaptopWindowsIcon /></div>
            )
        }

        if (id == "MacOS") {
            //return "🗓"
            return (
                <div style={{ marginRight: 5 }}>
                    <LaptopWindowsIcon /></div>
            )
        }

        return <div style={{ marginRight: 5 }}><AndroidIcon /></div>
    }


    return (

        <div>

            <Grid container spacing={2} mb={1.5}>
                <Grid item xs={12}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="start"
                        alignItems="center"


                    >


                        <IconButton
                             
                            size="small"

                            style={{ borderRadius: '10px', textTransform: 'none', boxShadow: 'none' }}
                            sx={{ mr: 1, textTransform: 'none', borderRadius: 0,  }}
                            onClick={() => { navigate("/edit", { state: { id: props.item.Id, name: props.item.Name?.String == "" ? "empty" : props.item.Name?.String } }) }}
                        >
                            <EditIcon />
                        </IconButton >


                        <IconButton
                             
                            size="small"

                            style={{ borderRadius: '10px', textTransform: 'none', boxShadow: 'none' }}
                            sx={{ mr: 1, textTransform: 'none', borderRadius: 0 }}
                            onClick={() => { props.setLoadingState(); props.onChangeProtocClick(props.item.Id, props.item.OnMulticonnection, props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id : 671643425, [7, 40, 41].includes(props.item.ServerTypeId), props.item.TariffId.Int64) }}
                        >
                            <RefreshIcon />
                        </IconButton >

                        {(props.data.length > 1) ?
                            <IconButton
                                 
                                size="small"

                                style={{ borderRadius: '10px', textTransform: 'none', boxShadow: 'none' }}
                                sx={{ textTransform: 'none',borderRadius: 0, }}
                                onClick={() => { props.setLoadingState(); props.onDeleteOpenClick(props.item.Id, props.item.OnMulticonnection, props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id : 671643425, [7, 40, 41].includes(props.item.ServerTypeId)) }}
                            >
                                <DeleteIcon />
                            </IconButton>
                            : <div></div>
                        }

                    </Grid>


                </Grid>

            </Grid>

            <div>

                <Grid container spacing={2} mb={1.5}>
                    <Grid item xs={5}>
                        <Typography>ID</Typography>
                    </Grid>
                    <Grid item xs={7}>
                        <Typography textAlign={'right'}>{props.item.Id}</Typography>
                    </Grid>
                </Grid>

                <Grid container spacing={2} mb={1.5}>
                    <Grid item xs={5}>
                        <Typography>Дата создания</Typography>
                    </Grid>
                    <Grid item xs={7}>
                        <Typography textAlign={'right'}>{formatDate} UTC</Typography>
                    </Grid>
                </Grid>

                <Grid container spacing={2} mb={1.5}>
                    <Grid item xs={5}>
                        <Typography>{t("region")}</Typography>
                    </Grid>
                    <Grid item xs={7}>
                        <Typography textAlign={'right'}><i className={"em em-flag-" + props.item.Flag?.String?.toString().toLowerCase()} aria-role="presentation" aria-label="Netherlands Flag"></i> {props.item.City?.String}</Typography>
                    </Grid>
                </Grid>

                <Grid container spacing={2} mb={1.5}>
                    <Grid item xs={5}>
                        <Typography>{t("server")}</Typography>
                    </Grid>
                    <Grid item xs={7} >
                        <Typography textAlign={'right'}>{props.item.Domain}</Typography>
                    </Grid>
                </Grid>

                <Grid container spacing={2} mb={1.5}>
                    <Grid item xs={5}>
                        <Typography>{t("status")}</Typography>
                    </Grid>
                    <Grid item xs={7}>
                        <Typography textAlign={'right'}>{props.item.IsEnabled ? "🟢 OK" : "🔴 "+t("disable")}</Typography>
                    </Grid>
                </Grid>

                <Grid container spacing={2} mb={1.5}>
                    <Grid item xs={5}>
                        <Typography>{t("device")}</Typography>
                    </Grid>
                    <Grid item xs={7}>
                        <div style={{
                            display: "flex", alignItems: "center",
                            justifyContent: "flex-end",
                            flexDirection: "row"
                        }}>
                            {/*   {getAvatar(props.item.Device.String)} */}
                            {
                                props.item.Device.String != null
                                    ? <Typography style={{ color: props.tg.themeParams.text_color ?? '#1E88E5' }} textAlign={'right'}>{props.item.Device.String}</Typography>
                                    : <Typography textAlign={'right'}>Не выбран</Typography>
                            }
                            {/*     <HelpIcon style={{ height: 10 }} /> */}
                        </div>

                    </Grid>
                </Grid>
                <Grid container spacing={2} mb={1.5}>
                    {/*    <Grid item xs={12}> */}
                    {/*       <pr  value={progress} /> */}
                    {/* </Grid> */}
                    {/*   <Grid item xs={7}>
<Typography textAlign={'right'}>{item.IsEnabled ? "🟢 OK" : "🔴 Заблокировано"}</Typography>
</Grid> */}
                </Grid>


                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                        borderRadius: '12px'
                    }}

                >
                    <img src={props.item.Code.replace('"', '').replace('"\n', '')} alt="Logo" />
                </Box>



                {
                    (props.data.length > 1) ?
                        props.isLoading1
                            ?
                            <div>{/* <LoadingButton

            sx={{ mb: 1, mt: 2 }}
            style={{
                width: '100%', background: '#ffc107', boxShadow: 'none'
            }} loading variant="outlined">
            Загрузка
        </LoadingButton>

            <LoadingButton

                sx={{ mb: 1, mt: 2 }}
                style={{
                    width: '100%', background: '#C62828', boxShadow: 'none'
                }} loading variant="outlined">
                Загрузка
            </LoadingButton> */}



                            </div>
                            :
                            <div>
                                {/*    <Button sx={{ mb: 1, mt: 2 }} style={{ width: '100%', background: '#ffc107', color: 'white', boxShadow: 'none' }} variant="contained" onClick={() => { props.setLoadingState(); onChangeProtocClick(item.Id, item.OnMulticonnection, props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id : 671643425, item.ServerTypeId == 7) }}>Сменить протокол</Button>
            <Button sx={{ mb: 1, mt: 2 }} style={{ width: '100%', background: '#C62828', color: 'white', boxShadow: 'none' }} variant="contained" onClick={() => { props.setLoadingState(); onDeleteClick(item.Id, item.OnMulticonnection, props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id : 671643425, item.ServerTypeId == 7) }}>Удалить</Button> */}
                            </div>
                        : props.isLoading1
                            ?
                            <div>
                                <LoadingButton

                                    sx={{ mb: 1, mt: 2 }}
                                    style={{
                                        width: '100%', background: '#ffc107', boxShadow: 'none'
                                    }} loading variant="outlined">
                                    Загрузка
                                </LoadingButton>
                            </div>
                            :
                            <div>
                                {/*       <Button sx={{ mb: 1, mt: 2 }} style={{ width: '100%', background: '#ffc107', color: 'white', boxShadow: 'none' }} variant="contained" onClick={() => { props.setLoadingState(); onChangeProtocClick(item.Id, item.OnMulticonnection, props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id : 671643425, item.ServerTypeId == 7) }}>Сменить протокол</Button>
*/}
                            </div>
                }

                <Box mt={2}>
                    <Button className='pay_button_instruction' variant="contained" aria-label="add to shopping cart" /* disabled={setEnabled()} */ sx={{ borderRadius: 0,  p: 1, width: '100%' }}
                        style={{ borderRadius: '10px', textTransform: 'none', boxShadow: 'none' }} onClick={() => props.onInstructionClick(props.item.Instruction.String)}>
                         {t("instruction")}
                        {/*  <CreditCardIcon sx={{ ml: 0.5 }} /> */}
                    </Button >
                </Box>
            </div>

        </div>

    );
}