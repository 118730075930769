/* export const base_url = "http://localhost:5010" */
/* 
export const base_url_instructions= "https://whypn.com" */
export const current_user = 718325694  
export const g_language = 'en' 
   

    export const base_url = "https://shai.test.5gst.ru" 
/* export const base_url = "https://account.whypn.com"
 */
export function getUrl (url, tg){
    let initDataURLSP = new URLSearchParams(tg.initData);
    var userId = tg.initDataUnsafe.user.id

    var hash = initDataURLSP.get('hash');

    var checkDataString = initDataURLSP.toString()?.replaceAll('&', '\n');

    return encodeURI(base_url + url + "?hash=" + hash + "&checkDataString="+checkDataString+"&userId"+userId+"&" )
/* return encodeURI(base_url + url + "?" ) */
}



export function getPostUrl (url, tg){
    let initDataURLSP = new URLSearchParams(tg.initData);
    
    var hash = initDataURLSP.get('hash');
    var checkDataString = initDataURLSP.toString()?.replaceAll('&', '\n');

    //return encodeURI(base_url + url + "?hash=" + hash + "&checkDataString="+checkDataString+"&" )

    return {
        url: base_url,
        body: {
            hash: hash,
            checkDataString: checkDataString
        }
    }
/* return encodeURI(base_url + url + "?" ) */
}
