import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CodeList from './CodeList';
import { Box } from '@mui/material';
import { current_user, getUrl } from '../../core/UrlService';

export default function CodeConainer(props: any) {

    const [data, setData] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(true);
    const [isLoading1, setIsLoading1] = React.useState(true);


    React.useEffect(() => {

        /* setIsLoading(true)
                      setIsLoading1(true) */

        let initDataURLSP = new URLSearchParams(props.tg.initData);
        var hash = initDataURLSP.get('hash');
        var checkDataString = initDataURLSP.toString().replaceAll('&', '\n');


        /*      let xhrURL = new URL('http://localhost:5010/api/connections');
             xhrURL.searchParams.set('id', (props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id :    current_user       ));
             xhrURL.searchParams.set('hash', hash as string);
             xhrURL.searchParams.set('checkDataString', checkDataString);
     
             let xhr = new XMLHttpRequest();
             xhr.open('GET', xhrURL);
             xhr.send();
             xhr.onload = function (result) {
     
                  
                 setIsLoading(false)
     
            
             } */
        /*        
                   fetch( getUrl("/api/connections", props.tg) + "&id=" + (props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id :    current_user       ))
                      .then(res => res.json())
                      .then(
                          (result) => {
          
          
                               
                              setIsLoading(false)
                              setIsLoading1(false)
                              setData(result);
                          },
                          (error) => {
        
                          }
                      ) */

    }, [])

    var refresh = () => {
        setIsLoading(true)
        setIsLoading1(true)
        fetch(getUrl("/api/connections", props.tg) + "id=" + (props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id : current_user))
            // fetch("http://localhost:5010" + "/api/connections?id=" + (props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id :    current_user       ))
            .then(res => res.json())
            .then(
                (result) => {

                    setIsLoading(false)
                    setIsLoading1(false)
                    setData(result);
                },
                (error) => {
                    //setIsLoaded(true);
                    //setError(error);
                }
            )
    }


    var setLoadingState = () => {
        setIsLoading1(true)
    }

    return (
        <CodeList
            info={props.info}
            tg={props.tg}
            data={props.data}
            isLoading={false}
            balance={props.balance}
            isLoading1={false}
            setLoadingState={setLoadingState}
            refresh={() => { refresh() }}
        ></CodeList>
    );
}