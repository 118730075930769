import * as React from 'react';
import Typography from '@mui/material/Typography';
import { Box, Button, Grid, IconButton, Link } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useParams } from 'react-router-dom';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useTranslation } from 'react-i18next';
import { g_language } from '../core/UrlService';
import { bg_color, text_color } from '../themes/themeTelegram';

export default function Support(props: any) {

    const { t, i18n } = useTranslation()
    React.useEffect(() => {
        i18n.changeLanguage(props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.language_code : g_language);
    }, [])

    const [data, setData] = React.useState([]);
    const navigate = useNavigate();
    const params = useParams();

    var onCreateCodeClick = () => {
        /*    fetch("http://localhost:5010" + '/api/update?id=' + (props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id :   current_user         ) + '&name=' + "test")
               .then(res =>
                   navigate("history")
               ); */
        window.open('https://donate.qiwi.com/payin/WhyPN', '_blank')
    }

    var onBackClick = () => {
        navigate("/")
    }

    return (
        <Box /* style={{ background:  bg_color }} */>
            <Box mb={2} mt={1} sx={{
                /*    background:  bg_color, */
                display: 'flex',
                color: text_color,
                flexDirection: 'row',
                alignItems: 'center',

            }}>
                <IconButton aria-label="delete" sx={{ p: 0, mr: 1 }} onClick={onBackClick}>
                    <ArrowBackIcon />
                </IconButton>
                <Typography color={text_color} variant="h6" gutterBottom sx={{ m: 0 }}> {t("support.support_title")}</Typography>
            </Box>
            <Box style={{ background:  bg_color, borderRadius: '12px', boxShadow: 'none', color: text_color }} sx={{ p: 2 }}>
                {t("support.support_desc_1")} <b>WhyPN</b> {t("support.support_desc_2")}


            </Box>




            <Box mt={2}>
                <Button style={{
                    width: '100%',
           
                    boxShadow: 'none',
                    borderRadius: '12px'
                }} variant="contained" onClick={onCreateCodeClick}>  {t("support.support_btn")}</Button>
            </Box>
            <Box mt={2}>
                <Typography color={text_color} style={{ width: '100%', fontSize: '12px' }} variant="body2">{t("support.support_sub_desc")}
                </Typography>
            </Box>
        </Box >
    );
} 