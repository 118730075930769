import { Avatar, Box, Button, Card, CircularProgress, IconButton, List, ListItem, ListItemAvatar, ListItemText, Skeleton, Typography } from '@mui/material';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import "./styles.css"
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useNavigate } from 'react-router-dom';
import HelpIcon from '@mui/icons-material/Help';
import React from 'react';
import { current_user, getUrl } from '../../core/UrlService';
import { bg_color } from '../../themes/themeTelegram';




export default function NewUserPay(props: any) {
    const navigate = useNavigate();
    const [data, setData] = React.useState([]);
    const [visible, setVisible] = React.useState(false);


    React.useEffect(() => {
        fetch(getUrl("/api/sub", props.tg) + "id=" + (props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id :    current_user       ))
        //fetch("http://localhost:5010" + "/api/sub?id=" + (props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id :    current_user       ))
            .then(res => res.json())
            .then(
                (result) => {
                    setVisible(result)
                    /* setData(result.Connections); */
                },
                (error) => {
                    //setIsLoaded(true);
                    //setError(error);
                }
            )
    }, [])

    /*  const onMainBlockClick = () => {
         navigate("/sub_desc")
     } */

    const onFreeClick = () => {
         
        navigate("/first", { state: (props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id :    current_user       ) } )
    }

    return (
        <Box /* onClick={onMainBlockClick} */>

           {/*  {visible
                ? <div>Вы уже подписаны</div>
                : */}
                <Button   variant="contained" aria-label="add to shopping cart" /* disabled={setEnabled()}  */ sx={{/*  boxShadow:'0px 0px 9px 1px #ffc107', */ borderRadius: 0, p: 1, width: '100%' }}
                    style={{ borderRadius: '10px', textTransform: 'none', boxShadow: 'none', border: '1px solid #ffc107',  color: props.tg.themeParams.text_color ?? '#1E88E5', background:  bg_color }} onClick={onFreeClick}>
                    Получить сразу платное подключение
                    {/*   <CreditCardIcon sx={{ ml: 0.5 }} /> */}
                </Button >
         
        </Box>
    );
};
