import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Moment from 'moment';
import { Box, IconButton, Skeleton, Typography } from '@mui/material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import LanguageIcon from '@mui/icons-material/Language';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import CelebrationIcon from '@mui/icons-material/Celebration';
import HandshakeIcon from '@mui/icons-material/Handshake';
import StarIcon from '@mui/icons-material/Star';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import React from 'react';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import { useTranslation } from 'react-i18next';
import { g_language } from '../../core/UrlService';

import light_precomp from "../../assets/sun.json";
import Lottie from 'lottie-react';
import { bg_color, text_color } from '../../themes/themeTelegram';

export default function HistoryList(props: any) {
    const navigate = useNavigate();

    const TotalIncomeCard = () => {

        return (
            <List  sx={{ minWidth: '100%', maxWidth: 360, color: text_color, mt:2 }}>
                {
                    [1, 2, 3, 4, 5, 6, 7, 8].map((x: any) => {
                        const formatDate = Moment(x.Time).format('DD.MM.YYYY')
                        var sum = x.TotalNow - x.TotalBefore

                        return (
                            <div style={{ width: '100%' }} >
                                <ListItem
                                    sx={{ mb: 2 }}
                                    style={{ boxShadow: 'none', borderRadius: '12px', width: '100%', color: text_color, background:  bg_color }}

                                >
                                    <Skeleton variant="circular" sx={{ mb: 1, mt: 1, mr: 1 }}>
                                        <Avatar />
                                    </Skeleton>

                                    <Box sx={{ display: 'flex', width: '100%', flexWrap: 'wrap' }}>
                                        <Skeleton style={{ width: '100%' }}></Skeleton>
                                        <Skeleton style={{ width: '100%' }}></Skeleton>
                                    </Box>

                                </ListItem>
                                {/*  <Divider variant="inset" component="li" /> */}
                            </div>
                        )
                    })
                }
            </List>
        )


    };

    const {t, i18n} = useTranslation ()
    React.useEffect(()=>{
        props.tg.BackButton.show()
        props.tg.BackButton.onClick(()=>{
            navigate("/nav_list")
        })
        i18n.changeLanguage(props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.language_code : g_language);
    })
    
    var getAvatar = (id: number) => {
        if (id == 1) {
            // return "🤝"
            return (
                <Avatar sx={{ bgcolor: '#69F0AE' }}>
                    <HandshakeIcon />
                </Avatar>
            )
        }
        if (id == 5) {
            //return "🌐"
            return (
                <Avatar sx={{ bgcolor: '#2196F3' }}>
                    <LanguageIcon />
                </Avatar>
            )
        }
        if (id == 3) {
            return (
                <Avatar sx={{ bgcolor: '#FFC107' }}>
                    <CreditCardIcon />
                </Avatar>
            )
        }
        if (id == 4) {
            //return "🎉"
            return (
                <Avatar sx={{ bgcolor: '#673AB7' }}>
                    <CelebrationIcon />
                </Avatar>
            )
        }
        if (id == 7 || id == 40) {
            //return "🗓"
            return (
                <Avatar sx={{ bgcolor: '#D84315' }}>
                    <CalendarMonthIcon />
                </Avatar>
            )
        }

        if (id == 7 || id == 40) {
            //return "🗓"
            return (
                <Avatar sx={{ bgcolor: '#ffe537' }}>
                    <CalendarMonthIcon />
                </Avatar>
            )
        }

        //return "⭐️"
        return (
            <Avatar sx={{ bgcolor: '#4527A0' }}>
                <ThumbUpAltIcon />
            </Avatar>
        )
    }
    var onBackClick = () => {
        navigate("/")
    }

    
    var getTypeText = (id:number) => {
        return t("history_page.types."+id)
    }

    return (
        <div>
            <Box mb={1} mt={1}  sx={{
                display: 'flex',
                color: text_color,
                flexDirection: 'row',
                alignItems: 'center',

            }}>
               {/*  <IconButton aria-label="delete" sx={{ p: 0, mr: 1 }} style={{ color: text_color }} onClick={onBackClick}>
                    <ArrowBackIcon />
                </IconButton> */}
                <Typography color={text_color} variant="h6" gutterBottom sx={{ m: 0 }}> {props.t("history_page.name")}</Typography>
            </Box>
            {
                props.isLoading
                ?
                
                //<Lottie style={{ height: 200 }} animationData={light_precomp} loop={true} />

                TotalIncomeCard()

                : props.data.length == 0
                    ?   <Typography color={text_color} textAlign='center' gutterBottom sx={{ m: 0 }}> {props.t("history_page.empty")}</Typography>
                    : <List sx={{ minWidth: '100%', maxWidth: 360, color: text_color }}>
                        {
                            props.data.map((x: any) => {
                                const formatDate = Moment(x.Time).format('DD.MM.YYYY')
                                var sum = x.TotalNow - x.TotalBefore

                                return (
                                    <div style={{ width: '100%' }} >
                                        <ListItem
                                            sx={{ mb: 2 }}
                                            style={{ boxShadow: 'none', borderRadius: '12px', width: '100%', color: text_color, background:  bg_color }}
                                            secondaryAction={
                                                <div>{sum > 0 ? "+" + Math.round(sum) + " WP" : Math.round(Math.abs(sum)) + " WP"}</div>
                                            }
                                        >
                                            <ListItemAvatar>
                                                {getAvatar(x.TypeId)}
                                            </ListItemAvatar>
                                            <ListItemText primary={formatDate} secondary={getTypeText(x.TypeId)}
                                                secondaryTypographyProps={{ color: text_color }}
                                                style={{ color: text_color }} />
                                        </ListItem>
                                        {/*  <Divider variant="inset" component="li" /> */}
                                    </div>
                                )
                            })
                        }
                    </List>
            }
        </div>
    );
}