import { Avatar, Box, Card, CircularProgress, IconButton, List, ListItem, ListItemAvatar, ListItemText, Skeleton, ThemeProvider, Typography } from '@mui/material';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import "./styles.css"
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useLocation, useNavigate } from 'react-router-dom';
import HelpIcon from '@mui/icons-material/Help';
import React from 'react';
import { current_user, g_language, getUrl } from '../../core/UrlService';
import { locale } from 'moment';
import { useTranslation } from 'react-i18next';
import { bg_color, text_color, themeTelegram } from '../../themes/themeTelegram';


const TotalIncomeCard = () => (
    <Card sx={{ p: 2 }}>
        <List sx={{ py: 0 }}>
            <ListItem alignItems="center" disableGutters sx={{ py: 0 }}>
                <ListItemAvatar>
                    <Skeleton variant="rectangular" width={44} height={44} />
                </ListItemAvatar>
                <ListItemText
                    sx={{ py: 0 }}
                    primary={<Skeleton variant="rectangular" height={20} />}
                    secondary={<Skeleton variant="text" />}
                />
            </ListItem>
        </List>
    </Card>
);


export default function Tariff_v2(props: any) {
    const navigate = useNavigate();
    const location = useLocation();
    const [data, setData] = React.useState([]);
    const { t, i18n } = useTranslation()

    React.useEffect(() => {
        i18n.changeLanguage(props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.language_code : g_language);

        fetch(getUrl("/api/tariff_info", props.tg) + "id=" + (props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id : current_user))
            .then(res => res.json())
            .then(
                (result) => {
                    setData(result);
                },
                (error) => {
                    //setIsLoaded(true);
                    //setError(error);
                }
            )
    }, [])

    const onMainBlockClick = () => {
        navigate("/tariff_desc", { state: { conn: props.data } })
    }


    var getName = () => {


        let n = Math.abs(props.data.length);
        n %= 100;
        if (n >= 5 && n <= 20) {
            return t("connection_1").toLowerCase();;
        }
        n %= 10;
        if (n === 1) {
            return t("connection_3").toLowerCase();;
        }
        if (n >= 2 && n <= 4) {
            return t("connection_2").toLowerCase();;
        }
        return t("connection_1").toLowerCase();

        /*   if (location.state.conn == 1){
              return "подключение"
          }
          if (location.state.conn > 1 && location.state.conn < 5){
              return "подключений"
          }
  
          if (location.state.conn > 5){
              return "подключений"
          } */
    }

    return (
        <ThemeProvider theme={themeTelegram}>
            <Box sx={{ p: 2, background: bg_color, borderRadius: '12px' }} onClick={onMainBlockClick}>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}>
                    <Typography variant="caption" sx={{  color:text_color, fontSize: ' 0.675rem', textTransform: 'uppercase' }} >
                        {t("tariff")}
                    </Typography>
                    <IconButton
                     
                        sx={{ alignItems: 'center', p: 0, fontSize: ' 0.875rem' }}
                    >

                        <HelpIcon style={{  color:themeTelegram.palette.primary.main}} fontSize="inherit" />
                    </IconButton>
                </Box>

                <List sx={{ py: 0 }}>
                    <ListItem alignItems="center" disableGutters sx={{ py: 0, width: '100%' }}>


                        {
                            props.isLoading
                                ?
                                <ListItemText
                                    sx={{
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        display: 'flex',
                                        width: '100%'
                                    }}
                                    primary={
                                        <Typography variant="h5" sx={{ color: themeTelegram.palette.text.primary, width: '100%' }}>
                                            <Skeleton />
                                        </Typography>
                                    }
                                    secondary={
                                        <Typography variant="subtitle2" sx={{ color: themeTelegram.palette.text.primary, width: '100%' }} >
                                            <Skeleton sx={{ width: '100%' }} />
                                        </Typography>

                                    }
                                />
                                :
                                /*        data.length == 0
                                           ? <ListItemText
                                               sx={{
                                                   flexDirection: 'column',
                                                   alignItems: 'center',
                                                   display: 'flex',
                                                   width: '100%'
                                               }}
                                               primary={
                                                   <Typography variant="h5" sx={{ color: props.tg.themeParams.text_color ?? 'black', width: '100%' }}>
                                                       <Skeleton />
                                                   </Typography>
                                               }
                                               secondary={
                                                   <Typography variant="subtitle2" sx={{ color: props.tg.themeParams.text_color ?? 'black', width: '100%' }} >
                                                       <Skeleton sx={{ width: '100%' }} />
                                                   </Typography>
                   
                                               }
                                           />
                                           : */

                                <ListItemText
                                    sx={{
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        display: 'flex'
                                    }}
                                    primary={
                                        <Typography variant="h5" sx={{ color: themeTelegram.palette.text.primary}}>
                                            {(props.data as any)?.length}
                                        </Typography>
                                    }
                                    secondary={
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <Typography variant="subtitle2" sx={{color: themeTelegram.palette.text.primary }} >
                                                {getName()}
                                            </Typography>
                                        </Box>
                                    }


                                />
                            /*       <ListItemText>
                                      <div style={{
                                           flexDirection: 'column',
                                           alignItems: 'center',
                                           display: 'flex'
                                      }}>
                                          <Typography variant="h5" sx={{ color: props.tg.themeParams.text_color ?? 'black' }}>
                                              {getTariffPrcie()} WP
                                          </Typography>
                                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                              <Typography variant="subtitle2" sx={{ color: props.tg.themeParams.text_color ?? 'black' }} >
                                                  Ежедневно
                                              </Typography>
      
                                             
                                          </Box>
                                      </div>
                                  </ListItemText> */

                        }
                    </ListItem>
                </List>
            </Box>
        </ThemeProvider>

        /*    <Box
               onClick={clickOnBalanceBox}
               className='test'
               sx={{ p: 2 }}
               style={{
                   background:  bg_color,
               }}
           >

               <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                   <Typography variant="subtitle2" sx={{ color: props.tg.themeParams.text_color ?? 'black' }} >
                       Баланс
                   </Typography>

               </Box>

               <List sx={{ py: 0 }}>
                   <ListItem alignItems="center" disableGutters sx={{ py: 0, textAlign:'center' }}>
                       <ListItemAvatar>
                           <Avatar
                               variant="rounded"
                               sx={{
                                   width: 55, height: 55,
                                   backgroundColor: 'rgb(255, 248, 225);',
                                   color: 'rgb(255, 193, 7)',
                                   mr: 2
                               }}
                           >
                               <CreditCardIcon sx={{ fontSize: 30 }} fontSize="inherit" />
                           </Avatar>
                       </ListItemAvatar>
                       {props.data.length == 0
                           ? <CircularProgress color="secondary" />
                           : <ListItemText

                               sx={{
                                   py: 0,
                                   mt: 0.45,
                                   mb: 0.45,
                   
                               }}
                               primary={
                                   <Typography variant="h5" sx={{ color: props.tg.themeParams.text_color ?? 'black' }} className=''>
                                       {props.data[0].TotalNow} WP
                                   </Typography>
                               }
                               secondary={
                                   <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                                       <Typography variant="subtitle2" sx={{ color: props.tg.themeParams.text_color ?? 'black' }} >
                                           Баланс
                                       </Typography>

                                       <IconButton
                                           style={{ color: props.tg.themeParams.text_color ?? '#1E88E5' }}
                                           sx={{ fontSize: 12, alignItems: 'center', p: 0 }}
                                       >
                                           <ArrowForwardIosIcon fontSize="inherit" />
                                       </IconButton>
                                   </Box>
                               }
                           />
                       }
                   </ListItem>
               </List>

           </Box>
*/



    );
};
