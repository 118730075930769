import { Avatar, Box, Chip, FormControlLabel, FormGroup, Grid, Paper, Typography } from "@mui/material"
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';
import { motion } from "framer-motion";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { g_language } from "../core/UrlService";
import { bg_color, secondary_bg_color, text_color } from "../themes/themeTelegram";
export default function RulePage_1(props: any) {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    React.useEffect(() => {

        props.tg.MainButton.setText(t("main_button.next")) //  метод для задания текста
        props.tg.MainButton.show() // показать кнопку 
       
        props.tg.MainButton.onClick(() => {
            navigate("/rule_page_2")
        })
    }, [])

    React.useEffect(() => {
        i18n.changeLanguage(props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.language_code : g_language);

    }, [])
    return (
        <Box sx={{ height: "100%" }}>
          {/*   <motion.div


                style={{ height: "100%", display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.5 }}

            > */}


                <Box sx={{ mt: 2 }} >

                    <Typography variant="body1" sx={{  color: text_color, width: '100%' }}>
                        <b> {t("rule_page_1.main_title")} </b>
                    </Typography>
                    <Typography variant="body1" sx={{ color: text_color, width: '100%', mt: 2 }}>
                        {t("rule_page_1.title_1")} <b>{t("rule_page_1.connections")}: </b>
                    </Typography>
                    <Typography variant="body1" sx={{color:  text_color, width: '100%' }}>
                        {t("rule_page_1.title_2")}
                    </Typography>

                    <Typography variant="body1" sx={{ color: text_color, width: '100%', mt: 2 }}>
                        {t("rule_page_1.title_3")}
                    </Typography>

                    <Typography variant="body1" sx={{ color: text_color, width: '100%', mt: 2 }}>
                        {t("rule_page_1.title_4")}:
                    </Typography>

                    {/* 
                    <ul>
                        <li style={{ text_color }}> <Typography variant="body1" sx={{ text_color, width: '100%', mt: 2 }}>
                            iOS и iPadOS 17 и выше;
                        </Typography></li>
                        <li style={{ text_color }}> <Typography variant="body1" sx={{ text_color, width: '100%', mt: 2 }}>
                            macOS 13.2 и выше;
                        </Typography></li>
                        <li style={{ text_color }}> <Typography variant="body1" sx={{ text_color, width: '100%', mt: 2 }}>
                            Android 11 и выше;
                        </Typography></li>
                        <li style={{ text_color }}> <Typography variant="body1" sx={{ text_color, width: '100%', mt: 2 }}>
                            Windows 10 и выше;
                        </Typography></li>
                    </ul>

 */}



                    <Typography variant="body1" sx={{ color: text_color, width: '100%' }}>
                        - {t("rule_page_1.title_5_1")};
                    </Typography>
                    <Typography variant="body1" sx={{ color: text_color, width: '100%' }}>
                        - {t("rule_page_1.title_5_2")};
                    </Typography>
                    <Typography variant="body1" sx={{ color: text_color, width: '100%' }}>
                        - {t("rule_page_1.title_5_3")};
                    </Typography>
                    <Typography variant="body1" sx={{ color: text_color, width: '100%' }}>
                        - {t("rule_page_1.title_5_4")};
                    </Typography>




                </Box>

                {/* <Box sx={{ mt: 2, mb: 2 }} >
                <Chip label="Пропустить обучение" />
                <Typography sx={{ textAlign: 'center', position: 'fixed', bottom: 20, left: 5, right: 5, text_color, width: '100%' }} onClick={() => { navigate("/set_up") }}>
                    <u>Пропустить обучение</u>
                </Typography>
            </Box> */}


<Box sx={{ mt: 2, mb: 2, textAlign: 'center', position: 'fixed', bottom: 5, left: 5, right: 5 }} >
                    <Chip style={{ background: bg_color}} label={<Typography variant="caption" sx={{ color: text_color, width: '100%' }} onClick={() => { navigate("/set_up") }}>
                    {t("rule_page_1.chip")}
                    </Typography>} />
                </Box>

          {/*   </motion.div> */}

        </Box>


    )
}