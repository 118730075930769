import * as React from 'react';
import { styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import TagFacesIcon from '@mui/icons-material/TagFaces';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import DirectionsIcon from '@mui/icons-material/Directions';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import TelegramIcon from '@mui/icons-material/Telegram';
import { Avatar, Box, Button, Grid, Input, InputAdornment, OutlinedInput, Skeleton, TextField, Typography } from '@mui/material';
/* import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'; */
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import { NumericFormat } from 'react-number-format';
import { NumberFormatCustom1 } from '../../core/FormattedInputs';
import { current_user, g_language, getUrl } from '../../core/UrlService';
import { Navigate, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { themeTelegram } from '../../themes/themeTelegram';
interface ChipData {
    key: number;
    label: number;
}

const ListItem = styled('li')(({ theme }) => ({
    margin: theme.spacing(0.5),
}));

interface State {
    numberformat: string;
}

export default function PayList(props: any) {

    const {t, i18n} = useTranslation ()

    const [chipData, setChipData] = React.useState<readonly ChipData[]>([
        { key: 0, label: 3 },
        { key: 1, label: 15 },
        { key: 2, label: 30 },
        { key: 3, label: 45 },

    ]);
    const navigate = useNavigate();

    var hasTm = () => {
        var c = props.tarif.find(x => x.ServerTypeId == 41)

        return c != null
    }
    const [description, setDescription] = React.useState("");
    const [sumToPay, setSumToPay] = React.useState();
    const [values, setValues] = React.useState<State>({
        numberformat: "0"
    });

    const handleDelete = (chipToDelete: ChipData) => () => {
        setChipData((chips) => chips.filter((chip) => chip.key == chipToDelete.key));
    };

    var getAvailableConn = () => {
        return props.tarif.filter(x => x.ServerTypeId != 41)
    }

    var getConn = () => {
        return props.tarif
    }


    var geTmConn = () => {
         
        return props.tarif.filter(x => x.ServerTypeId == 41)
    }


    var getMinSum  = () => {
        var sum = 0; 

        props.tarif.forEach(x=>{

            sum += x.TariffPrice.Int64
        })

        return sum;
    }

    var getMaxSum  = () => {
        
        var sum = 0; 

        props.tarif.forEach(x=>{

            sum += x.TariffPrice.Int64
        })

        return 100000//sum + sum * 3;
    }

    const onChipClick = (key: any) => {
        setSumToPay(key)

        setValues({
            ...values,
            "numberformat": key
        });

       /*  if (geTmConn().length > 0 ){
            setDescription("на 1 месяц для текущей страны")

             
            if (Number(key) > getMaxSum()) {
                setDescription("макс. сумма " + getMaxSum() + " WP")
            }
            else {
                if (key  < getAvailableConn().length * 3 )
                    setDescription("мин. сумма " + (getAvailableConn().length * 3) + " WP)
            }

        }
        else  */if (getAvailableConn().length > 0)
            setDescription("на " + (key / (getAvailableConn().length * 3)) + " " + t("days_at_the_current"))

            //setDescription( t("on") + " " + (key / (getAvailableConn().length * 3)) + " " + t("days_at_the_current"))
    }


    const onTmChipClick = (key: any) => {
        setSumToPay(key)

        setValues({
            ...values,
            "numberformat": key
        });

        setDescription("на 1 месяц для текущей страны")
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValues({

            "numberformat": event.target.value
        });


        if (event.target.value != null) {
            var a = event.target.value.toString().replace(' WP', '');

            if (geTmConn().length > 0) {

                setDescription("на 1 месяц для текущей страны")
                /* 
                            if (geTmConn().length > 0) { */
                if (Number(a) >  getMaxSum()) {
                    setDescription("макс. сумма " + getMaxSum() + " WP")
                }
                else {
                    if (Number(a)  < (getMinSum()) )
                        setDescription("мин. сумма " + (getConn().length * 3) + " WP")
                }
            }
            else {

                //setSumToPay(event.target.value as any)
                if (getConn().length > 0)
                    setDescription("на " + Math.round(((Number(a)) / (getAvailableConn().length * 3))) + " дней по текущему тарифу")
                    //setDescription( t("on") + " "  + Math.round(((Number(a)) / (getAvailableConn().length * 3))) + " " + t("days_at_the_current"))

                if (Number(a) < getConn().length * 3 * 3) {
                    setDescription("мин. сумма " + ((getConn()) != null ? (getConn())?.length * 3 * 3 : 0) + " WP")
                }
                else {
                    if (Number(a) >  getMaxSum()) {
                        setDescription("макс. сумма " + getMaxSum()+ " WP")
                    }
                    else {
                        if (a == "")
                            setDescription("мин. сумма " + ((getConn())?.length * 3 * 3) + " WP")
                    }
                }
            }
        }
    };


    const setEnabled = () => {

        var res = false;

        if (geTmConn().length > 0) {
            if (values.numberformat != null) {
                var a = values.numberformat.toString().replace(' WP', '');
                res = a == "0" || a == "" || Number(a) < getAvailableConn().length * 3 || Number(a) > getMaxSum();
            }
        }
        else {
            if (values.numberformat != null) {
                var a = values.numberformat.toString().replace(' WP', '');
                res = a == "0" || a == "" || Number(a) < getAvailableConn().length * 3 * 3 || Number(a) > getMaxSum();
            }
        }

        /*    if (res) {
               if (sumToPay != "" && sumToPay != "0")
               res = Number(sumToPay) < conn.length * 3 * 3 || Number(sumToPay) > conn.length * 3 * 45;
           } */
        //  if (sumToPay != null) {
        /*     if (Number(sumToPay) < conn.length * 3 * 3) {
                setDescription("мин. сумма " + (conn.length * 3 * 3) + " WP")
            }
            else {
                if (Number(sumToPay) > conn.length * 3 * 45) {
                    setDescription("макс. сумма " + (conn.length * 45 * 3) + " WP")
                }
                else {
                    if (values.numberformat == "")
                        setDescription("мин. сумма " + (conn.length * 3 * 3) + " WP")
                }
            } */
        // }
        return res

    }

    /*  const [conn, setConn] = React.useState(null); */

    var onPayClick = () => {
        var sum = values.numberformat
        var a = values.numberformat.toString().replace(' WP', '');
        navigate("/pay_detail",  { state: { amount: a } })

      /*   var sum = values.numberformat
        var a = values.numberformat.toString().replace(' WP', '');
        window.open("https://pay.whypn.com/pay?user_id=" + (props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id : current_user) + "&amount=" + a + "&key=5&status=1", "_blank") */
    }

    var onWalletClick = () => {
        
        var ar = 7//values.numberformat.toString().replace(' WP', '') > 10;
        fetch(getUrl("/api/create_order", props.tg)+"amount="+ar+"&currencyCode="+"RUB"+"&chatid="+(props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id : current_user))
        //fetch("http://localhost:5010" + "/api/available")
        .then(res => res.json())
        .then(
            (result) => {

                 
                window.open(result.data.directPayLink, "_blank")
              
            },
            (error) => {
                //setIsLoaded(true);
                //setError(error);
            })
            
       // window.open("https://pay.whypn.com/pay?user_id=" + (props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id : current_user) + "&amount=" + a + "&key=5&status=1", "_blank")
    }

    React.useEffect(() => {
        i18n.changeLanguage(props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.language_code : g_language);


        var a = getAvailableConn().length * 3 * 30
        setValues({
            ...values,
            "numberformat": a as any
        });
        setDescription( t("on") + " " + 30 + " " + t("days_at_the_current"))
       

        /*   fetch("http://localhost:5010" + "/api/connections?id=" + (props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id :    current_user              current_user          ))
              .then(res => res.json())
              .then(
                  (result) => {
                      setConn(result);
                      var a = result.length * 3 * 30
                      setValues({
                          ...values,
                          "numberformat": a as any
                      });
  
                      setDescription("на 30 дней по текущему тарифу")
                  },
                  (error) => {
                     
                  }
              ) */
    }, [])


    return (
        /*         <Box>
        
                    <Box
                        sx={{
                            display: 'flex',
                            '& > :not(style)': {
                                m: 1,
                                width: "100%"
        
                            },
                        }}
                    >
                        {
                            [3, 15, 30, 45].map(x => {
                                return (
                                    <Pay day_count={x}></Pay>
                                )
                            })
                        }
                    </Box>
                </Box> */
        <Box>
            {props.tarif != null && (props.tarif).IsEmpty == true

                ? <div></div>
                : <Paper
                    sx={{
                        background: themeTelegram.palette.primary.main,
                        display: 'flex',
                        boxShadow: 'none',
                        flexDirection: 'column',
                        /*   justifyContent: 'center',
                          flexWrap: 'wrap', */
                        borderRadius: '12px',
                        listStyle: 'none',
                        p: 2,
                        m: 0,

                    }}
                    component="ul"
                >
                    <Typography variant="caption" sx={{ color: themeTelegram.palette.text.secondary, fontSize: ' 0.675rem', mb: 1,  textTransform: 'uppercase'  }} >
                    {t("top_up_the_balance")}

                    
                    </Typography>
                    {/*      <Paper
                component="form" style={{ borderRadius: '10px' }}

                sx={{
                    background:  bg_color,
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                    mb: 1,
                    boxShadow: 'none',
                    border: '1px solid #edeef0'
                }}
            > */}

                    {/*     <InputBase
                    type="number"
                    sx={{

                        ml: 1, flex: 1
                    }}
                    onChange={(event: any) => {                                 //adding the onChange event
                        const onlyNums = event.target.value.replace(/[^0-9]/g, '');
                        if (onlyNums.length < 10) {
                            setSumToPay( onlyNums );
                        } else if (onlyNums.length === 10) {
                            const number = onlyNums.replace(
                                /(\d{3})(\d{3})(\d{4})/,
                                '($1) $2-$3'
                            );
                            setSumToPay(number );
                        }
                     
                       // setSumToPay(event.target.value)
                    }}
                    value={sumToPay}
                    style={{ borderRadius: '10px', width: '100%', boxShadow: 'none' }}
                    inputProps={{ 'aria-label': 'search google maps' }}
                /> */}
                    {/*  <Box sx={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                mb: 1,
                boxShadow: 'none',
                gap: '10px'
            }}>
              

                <TextField
                    placeholder="От 9 WP до 450 WP"
                    sx={{ width: '100%', borderRadius: '10px' }}
                    style={{ borderRadius: '10px' }}
                    value={sumToPay}
                    onChange={handleChange}
                    name="numberformat"
                    id="formatted-numberformat-input"
                    InputProps={{
                        inputComponent: NumberFormatCustom1 as any
                    }}
                    className="custom_number"
                    variant="outlined"
                    size="small"
                />

                <Button   variant="contained" aria-label="add to shopping cart" disabled={setEnabled()} sx={{ color: 'white', borderRadius: 0, backgroundColor: '#ffc107', p: 1 }}
                    style={{ borderRadius: '10px', textTransform: 'none', boxShadow: 'none' }}  >
                    Пополнить
                    <CreditScoreIcon  />
                </Button >
            </Box> */}
                    <Grid container spacing={2} mb={1}>

                        <Grid item md={3} xs={6} >
                            {/*        <TextField
                        placeholder={"От " + (conn.length * 3 * 3) + " WP до " + (conn.length * 3 * 45) + " WP"}
                        sx={{ width: '100%', borderRadius: '10px' }}

                        style={{ borderRadius: '10px' }}
                        value={values.numberformat}
                        onChange={handleChange}
                        name="numberformat"
                        id="formatted-numberformat-input"
                        InputProps={{
                            inputComponent: NumberFormatCustom1 as any
                        }}
                        className="custom_number"
                        variant="outlined"
                        size="small"
                    /> */}



                            <div style={{ display: 'flex' }} className="numeric_custom_div">
                                <NumericFormat
                                    onChange={handleChange}
                                    value={values.numberformat}
                                    /*     onValueChange={(values) => {
                
                                            onChange({
                                                target: {
                                                    name: props.name,
                                                    value: values.value
                                                }
                                            });
                                        }}
                 */
                                    className="numeric_custom"
                                    style={{ width: '100%', display: 'flex', color: themeTelegram.palette.text.primary }}

                                    suffix=" WP"
                                />
                            </div>
                        </Grid>
                        <Grid item md={2} xs={6}  >
                            <Button   variant="contained" aria-label="add to shopping cart" disabled={setEnabled()} sx={{  borderRadius: 0, p: 1, width: '100%' }}
                                style={{ borderRadius: '10px', textTransform: 'none', boxShadow: 'none' }} onClick={onPayClick}>
                                  {t("top_up")}
                                <CreditCardIcon sx={{ ml: 0.5 }} />
                            </Button >

                          {/*   <Button   variant="contained" aria-label="add to shopping cart" disabled={setEnabled()} sx={{ mt:2,color: 'white', borderRadius: 0, backgroundColor: 'rgb(25, 118, 210)', p: 1, width: '100%' }}
                                style={{ borderRadius: '10px', textTransform: 'none', boxShadow: 'none' }} onClick={onWalletClick}>
                                Wallet Pay
                                <TelegramIcon sx={{ ml: 0.5 }} />
                            </Button > */}
                        </Grid>
                    </Grid>
                    <Typography variant="caption" style={{ color: themeTelegram.palette.text.secondary }}>{description}</Typography>
                    {/*  <FormattedInputs></FormattedInputs> */}

                    {/*  <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                    <SearchIcon />
                </IconButton> */}

                    {/*     <Avatar
                    variant="rounded"
                    sx={{
                       
                        backgroundColor: 'rgb(255, 248, 225);',
                        color: 'rgb(255, 193, 7)'
                    }}
                >
                    <CreditScoreIcon fontSize="inherit" />
                </Avatar> */}

                    {/*   </Paper> */}
                    {
                        props.tarif.length == 0
                            ? <Skeleton height={40} />

                            : <div style={{ display: 'flex', /* justifyContent: 'center' */ }}>
                                {chipData.map((data) => {
                                    return (

                                        <ListItem key={data.key}>

                                            <Chip style={{width: '70px' }} variant="filled"
                                                clickable={true}
                                                onClick={() => onChipClick(data.label * getAvailableConn().length * 3)}
                                                label={(data.label * getAvailableConn().length * 3) + " WP "}
                                            /*   onDelete={data.label === 'React' ? undefined : handleDelete(data)} */
                                            />
                                        </ListItem>
                                    );
                                })}

                            </div>


                    }
                 {/*    {hasTm()
                        ? <Box sx={{ mt: 1 }}>
                            <Typography variant="caption" style={{ color: '#bbbbbb' }}>{"на 1 месяц для текущей страны"}</Typography>
                            <ListItem >
                                <Chip style={{ background: "rgb(255 112 7)", color: 'white', width: '60px' }} variant="filled"
                                    clickable={true}
                                    onClick={() => onTmChipClick(getMinSum())}
                                    label={getMinSum() + " WP "}                          
                                />
                            </ListItem>
                        </Box>
                        : <></>
                    } */}
                </Paper>
            }
        </Box>
    )
};
