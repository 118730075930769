import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { Chip, FormControl, Grid, IconButton, InputLabel, MenuItem, Radio, RadioGroup, Select, StepConnector, StepIconProps, TextField, Theme, ThemeProvider, createTheme, makeStyles, outlinedInputClasses, stepConnectorClasses, styled, useTheme } from '@mui/material';
/* import SettingsIcon from '@mui/icons-material/Settings'; */
/* import GroupAddIcon from '@mui/icons-material/GroupAdd'; */
import LocationOnIcon from '@mui/icons-material/LocationOn';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';
import AndroidIcon from '@mui/icons-material/Android';
import AppleIcon from '@mui/icons-material/Apple';
import LaptopWindowsIcon from '@mui/icons-material/LaptopWindows';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'; import "./stepper_style.css"
import { Sheet, Stack } from '@mui/joy';
import { ES, IT, NE, NL, RU, US } from 'country-flag-icons/react/3x2';
import { useLocation, useNavigate } from 'react-router-dom';
import CheckIcon from '@mui/icons-material/Check';
import DevicesIcon from '@mui/icons-material/Devices';
import * as Flags from 'country-flag-icons/react/3x2'
import SaveIcon from '@mui/icons-material/Save';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import { g_language, getUrl } from '../../core/UrlService';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import StarIcon from '@mui/icons-material/Star';

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { bg_color, button_color, text_color } from '../../themes/themeTelegram';
import i18n from '../../i18n';
import { useTranslation } from 'react-i18next';
const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 22,
        marginLeft: 22

    },
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        [`& .${stepConnectorClasses.vertical}`]: {
            marginLeft: 22
        },

    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            width: '2px !important',
            backgroundImage:
                'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(255, 193, 7) 100%)',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            width: '2px !important',


            backgroundImage:
                'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(255, 193, 7) 100%);',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        marginLeft: 10,
        height: 3,
        border: "none",
        /*  backgroundColor:
             theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0', */
        borderRadius: 1,

    },
}));


const ColorlibStepIconRoot = styled('div')<{
    ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
    backgroundColor:
        theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
        backgroundImage:
            'linear-gradient( 136deg, '+button_color+' 0%, '+button_color+' 100%)',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
        backgroundImage:
            'linear-gradient( 136deg, '+button_color+' 0%, '+button_color+' 100%);',
    }),
}));



function ColorlibStepIcon(props: StepIconProps) {
    const { active, completed, className } = props;

    const icons: { [index: string]: React.ReactElement } = {
        1: <DevicesIcon />,
        2: <LocationOnIcon />,
        3: <VideoLabelIcon />,
    };

    return (
        <ColorlibStepIconRoot
            ownerState={{ completed, active }}

            className={className}
        >
            {icons[String(props.icon)]}
        </ColorlibStepIconRoot>
    );
}



export default function EditCode(props: any) {
    const navigate = useNavigate();
    const [activeStep, setActiveStep] = React.useState(0);


    React.useEffect(() => {
        props.tg.BackButton.show()
        props.tg.BackButton.onClick(() => {
            navigate("/main")
        })

        i18n.changeLanguage(props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.language_code : g_language);
    }, [])
    
    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };



    const [device, setDevice] = React.useState(1);
    var onDeviceClick = (id: any) => {
        setDevice(id)

    }

    const [chips, setChips] = React.useState([]);



    const onMainBlockClick = () => {
        navigate("/tariff")
    }



    var getFlag = (value: any) => {

        const Flag = Flags[value];
        return <Flag title={value} style={{ height: 15 }} />
    }
    const [country, setCountries] = React.useState("NL");
    const [available, setAvailable] = React.useState([]);
    const [name, setName] = React.useState("");

    var onCountryClick = (id: any) => {

        setCountries(id)

    }

    const devices = [
        {
            id: 1,
            a: <Box display={"flex"} onClick={() => { onDeviceClick(1) }} style={{ borderRadius: '12px', boxShadow: 'none' }} >
                <AndroidIcon />
                <div style={{ paddingLeft: 5 }}>Android</div>
            </Box>
        },
        {
            id: 2,
            a: <Box display={"flex"} onClick={() => { onDeviceClick(2) }} style={{ borderRadius: '12px', boxShadow: 'none' }} >
                <AppleIcon />
                <div style={{ paddingLeft: 5 }}>IPhone</div>
            </Box>

        },
        {
            id: 3,
            a: <Box display={"flex"} className={"device_disable"} style={{ borderRadius: '12px', boxShadow: 'none' }} >
                <LaptopWindowsIcon />
                <div style={{ paddingLeft: 5 }}>MacOS</div>
            </Box>
        }, {
            id: 4,
            a: <Box display={"flex"} onClick={() => { }} className={"device_disable"} style={{ borderRadius: '12px', boxShadow: 'none' }} >
                <LaptopWindowsIcon />
                <div style={{ paddingLeft: 5 }}>Windows</div>
            </Box>
        },
        {
            id: 5,
            a: <Box display={"flex"} onClick={() => { }} className={"device_disable"} style={{ borderRadius: '12px', boxShadow: 'none' }} >
                <LaptopWindowsIcon />
                <div style={{ paddingLeft: 5 }}>IPad</div>
            </Box>
        }
    ]
    const location = useLocation();
    var onCreateCodeClick = () => {
        

        fetch(getUrl("/api/edit", props.tg) + "name=" + name + "&old_name=" + location.state.name + "&id=" + location.state.id)
            //fetch("http://localhost:5010" + "/api/available")
            .then(res => {
                
                navigate("/")})
            

        /* navigate(`/check`, { state: { id: country, device_id: device, name: name } }); */
        /*    fetch("http://localhost:5010" + '/api/create_by_country?id='
               + (props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id :    current_user       )
               + '&name='
               + (props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id : "test")
               + "&flag=" + country
           )
               .then(res =>
                   navigate("/")
               ); */
    }

    var get_flag = (code: any) => {

        return <US style={{ height: 15 }} />


    }


    var handleTextChange = (e) => {

        setName(e.target.value)
    }

   
    const customTheme = (outerTheme: Theme) =>
        createTheme({
            /*     palette: {
                    mode: outerTheme.palette.mode,
                }, */
            components: {
                MuiTextField: {
                    styleOverrides: {
                        /*   root: {
                              '--TextField-brandBorderColor': '#E0E3E7',
                              '--TextField-brandBorderHoverColor': '#B2BAC2',
                              '--TextField-brandBorderFocusedColor': '#6F7E8C',
                              '& label.Mui-focused': {
                                  color: props.tg.themeParams.text_color ?? '#1E88E5',
                              },
                          }, */
                    },
                },
                MuiOutlinedInput: {
                    styleOverrides: {
                        notchedOutline: {
/*                             borderColor: props.tg != undefined && props.tg.colorScheme == 'dark' ? props.tg.themeParams.text_color : '#bdbdbd',
 */                            border: "1px solid",
                            color: props.tg.themeParams.text_color ?? '#1E88E5',
                            borderColor: props.tg != undefined && props.tg.colorScheme == 'dark' ? props.tg.themeParams.text_color : '#bdbdbd'

                        },
                        root: {
                            [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
                                border: "1px solid",
                                color: props.tg.themeParams.text_color ?? '#1E88E5',
                                borderColor: props.tg != undefined && props.tg.colorScheme == 'dark' ? props.tg.themeParams.text_color : '#bdbdbd'
                            },
                            [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
                                border: "1px solid",
                                color: props.tg.themeParams.text_color ?? '#1E88E5',
                                /*     borderColor: '#bdbdbd' */
                                borderColor: props.tg != undefined && props.tg.colorScheme == 'dark' ? props.tg.themeParams.text_color : '#bdbdbd'
                            },
                        },
                    },
                },

            },
        });

    const onChipClick = (e: any) => {

        setName(e)
    }

    var keyPress = (e) => {

        if (e.key == 'Enter') {
            e.target.blur();
        }
    }
    
    const { t, i18n } = useTranslation()

    const outerTheme = useTheme()
    React.useEffect(() => {

        fetch(getUrl("/api/available", props.tg))
            //fetch("http://localhost:5010" + "/api/available")
            .then(res => res.json())
            .then(
                (result) => {

                    setAvailable(result);
                },
                (error) => {
                    //setIsLoaded(true);
                    //setError(error);
                }
            )


        fetch(getUrl("/api/parametrs", props.tg) + "obj=" + 'chips')
            //fetch("http://localhost:5010" + "/api/parametrs?obj=" + 'chips')
            .then(res => res.json())
            .then(
                (result) => {

                    setChips(result)
                },
                (error) => {
                    //setIsLoaded(true);
                    //setError(error);
                }
            )
    }, [])

    const steps = [
        {
            label: t("edit.name"),
            description: () => {

                return <div style={{ paddingTop: '10px' }}>
                    <Stack>

                        {/* <ValidationTextField value={name}  defaultValue={name} onChange={handleTextChange}></ValidationTextField> */}
                        {/*                         <TextField placeholder='Подключение'       InputProps={{sx: { borderRadius: '12px', borderColor: 'red' } }} value={name} defaultValue={name} style={{ borderRadius: '12px', color: props.tg.themeParams.text_color ?? '#1E88E5' }} 
                        
                        id="test" variant="outlined" onChange={handleTextChange}  focused /> */}
                        <ThemeProvider theme={customTheme(outerTheme)}>
                            <TextField placeholder={t("edit.conn")} sx={{ input: { color: text_color} }} InputProps={{ sx: { borderRadius: '12px' } }} value={name} defaultValue={name} style={{ borderRadius: '12px', color: button_color }}
                                onKeyDown={keyPress}
                                id="test" variant="outlined" onChange={handleTextChange} focused />
                        </ThemeProvider>
                        <div style={{ paddingTop: 10 }}>
                            {

                                chips.map((x: any) => {
                                    return (
                                        <Chip style={{ marginRight: 10 }} label={x.Value}  onClick={() => onChipClick(x.Value)} />
                                    )
                                })
                            }
                        </div>


                    </Stack>
                </div>
            },

            
        },
       

    ];

    var onBackClick = () => {
        navigate("/")
    }

    

    return (
        <Box sx={{ width: '100%' }} >

            <Box mb={1} mt={1} sx={{
                /*    background:  bg_color, */
                display: 'flex',
                color: bg_color,
                flexDirection: 'row',
                alignItems: 'center',

            }}>
             {/*    <IconButton aria-label="delete" sx={{ p: 0, mr: 1 }} style={{ }} onClick={onBackClick}>
                    <ArrowBackIcon />
                </IconButton> */}
                <Typography color={text_color} variant="h6" gutterBottom sx={{ m: 0 }}> {t("edit.title_1")}</Typography>
            </Box>

            <Box style={{  borderRadius: '12px', boxShadow: 'none' }} sx={{ p: 2 }}>
                <Box /* mb={1}  */ style={{ color: text_color   }}>
                {t("edit.current")}: {location.state.name}
                </Box>
            </Box>

            <Stepper activeStep={activeStep} connector={<ColorlibConnector />} orientation="vertical" style={{ color: text_color}} sx={{ mb: 1 }}>
                {steps.map((step, index) => (
                    <Step key={step.label} sx={{ mt: 1 }}/* style={{color:props.tg.themeParams.text_color ?? 'red'}} */ >
                        <StepLabel
                            color={text_color}
                            /*    style={{ color: props.tg.themeParams.text_color ?? 'red' }} */
                            StepIconComponent={ColorlibStepIcon}
                        /*   optional={
                              index === 1 ? (
                                  <Typography variant="caption">Last step</Typography>
                              ) : <div></div>
                          } */
                        >
                            <div style={{ color: text_color }}>
                                {step.label}
                            </div>
                        </StepLabel>
                        <StepContent>
                            {step.description()}
                            <Box sx={{ mb: 2 }} /* style={{background: props.tg != undefined && props.tg.colorScheme == 'dark' ? 'rgb(255 255 255 / 8%)' : 'rgb(248, 250, 252)'}} */>
                                <div>
                                    {
                                        index === steps.length - 1
                                            ? /* <Button
                                                variant="contained"
                                                onClick={onCreateCodeClick}
                                                sx={{ mt: 1, mr: 1 }}
                                            >
                                                Получить код
                                            </Button>
 */
                                            < Button onClick={onCreateCodeClick}   aria-label="add to shopping cart" /* disabled={setEnabled()} */ sx={{ mt: 1, mr: 1, borderRadius: 0}}
                                                style={{ borderRadius: '10px', textTransform: 'none', boxShadow: 'none' }}/*  onClick={onPayClick} */>
                                                {t("edit.save")}
                                                <SaveIcon sx={{ pl: 1 }} />
                                            </Button >
                                            : /* <Button
                                    variant="contained"
                                    onClick={handleNext}
                                    sx={{ mt: 1, mr: 1 }}
                                >
                                    Далее
                                </Button> */
                                            <Button onClick={handleNext}   variant="contained" aria-label="add to shopping cart" /* disabled={setEnabled()} */ sx={{ mt: 1, mr: 1,  borderRadius: 0,}}
                                                style={{ borderRadius: '10px', textTransform: 'none', boxShadow: 'none' }}/*  onClick={onPayClick} */>
                                                {t("edit.next")}
                                                <KeyboardArrowRightIcon sx={{}} />
                                            </Button >


                                    }

                                    {/*   <Button
                                        disabled={index === 0}
                                        onClick={handleBack}
                                        sx={{ mt: 1, mr: 1 }}
                                        style={{ color: props.tg.themeParams.text_color ?? 'red', textTransform: 'none' }}
                                    >
                                        Назад
                                    </Button> */}
                                </div>
                            </Box>
                        </StepContent>
                    </Step>
                ))
                }
            </Stepper >
            {/*  {
                activeStep === 1 && (
                    <Paper square elevation={0} sx={{ p: 3 }} style={{ background: props.tg != undefined && props.tg.colorScheme == 'dark' ? 'rgb(255 255 255 / 8%)' : 'rgb(248, 250, 252)' }}>
                        <Typography>All steps completed - you&apos;re finished</Typography>
                        <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                            Reset
                        </Button>
                    </Paper>
                )
            } */}
        </Box >
    );
}