import { Avatar, Box, Card, Chip, CircularProgress, Grid, IconButton, List, ListItem, ListItemAvatar, ListItemText, Skeleton, Typography } from '@mui/material';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import "./styles.css"
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useLocation, useNavigate } from 'react-router-dom';
import HelpIcon from '@mui/icons-material/Help';
import React from 'react';
import { current_user, g_language, getUrl } from '../../core/UrlService';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import LanguageIcon from '@mui/icons-material/Language';
import Moment from 'moment';
import CelebrationIcon from '@mui/icons-material/Celebration';
import HandshakeIcon from '@mui/icons-material/Handshake';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';
import AndroidIcon from '@mui/icons-material/Android';
import AppleIcon from '@mui/icons-material/Apple';
import LaptopWindowsIcon from '@mui/icons-material/LaptopWindows';
import StarIcon from '@mui/icons-material/Star';
import { useTranslation } from 'react-i18next';
import { bg_color, text_color } from '../../themes/themeTelegram';
const TotalIncomeCard = () => (
    <Card sx={{ p: 2 }}>
        <List sx={{ py: 0 }}>
            <ListItem alignItems="center" disableGutters sx={{ py: 0 }}>
                <ListItemAvatar>
                    <Skeleton variant="rectangular" width={44} height={44} />
                </ListItemAvatar>
                <ListItemText
                    sx={{ py: 0 }}
                    primary={<Skeleton variant="rectangular" height={20} />}
                    secondary={<Skeleton variant="text" />}
                />
            </ListItem>
        </List>
    </Card>
);


export default function TariffInfo_v2(props: any) {
    

    const navigate = useNavigate();
    const location = useLocation();
    const [data, setData] = React.useState([]);


    const { t, i18n } = useTranslation()
    React.useEffect(() => {
        props.tg.BackButton.show()
        props.tg.BackButton.onClick(() => {
            navigate("/main")
        })
      i18n.changeLanguage(props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.language_code : g_language);
    }, [])

    
    React.useEffect(() => {

        fetch(getUrl("/api/tariff_info", props.tg) + "id=" + (props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id : current_user))
            .then(res => res.json())
            .then(
                (result) => {
                    setData(result);
                },
                (error) => {
                    //setIsLoaded(true);
                    //setError(error);
                }
            )
    }, [])

    const onMainBlockClick = () => {
        navigate("/tariff_desc", { state: { conn: props.data } })
    }


    var getTariffPrcie = () => {

        var price = 0
        var conn = (props.data as any);
        for (var i = 0; i < conn?.length; i++) {
            price += conn[i].TarrifPerDay.Int64 == 0 ? 3 : 0
        }
        //  props.data == null ? 0 :(props.data as any)?.length * 3}

        return price;
    }


    var getDeviceIcon = (id: any) => {
        switch (id) {
            case 1:
                return <AndroidIcon />
            case 2:
                return <AppleIcon />
            case 3:
                return <LaptopWindowsIcon />
            case 4:
                return <LaptopWindowsIcon />
            default:
                return <LaptopWindowsIcon />
        }
    }

    var getAvatar = (id: any) => {
        if (id == "Android") {
            // return "🤝"
            return (
                <Avatar sx={{ bgcolor: '#69F0AE' }}>
                    <AndroidIcon />
                </Avatar>
            )
        }
        if (id == "IPhone") {
            //return "🌐"
            return (
                <Avatar sx={{ bgcolor: '#2196F3' }}>
                    <AppleIcon />
                </Avatar>
            )
        }
        if (id == "Windows") {
            return (
                <Avatar sx={{ bgcolor: '#FFC107' }}>
                    <LaptopWindowsIcon />
                </Avatar>
            )
        }

        if (id == "MacOS") {
            //return "🗓"
            return (
                <Avatar sx={{ bgcolor: '#D84315' }}>
                    <LaptopWindowsIcon />
                </Avatar>
            )
        }




        //return "⭐️"
        return (
            <Avatar sx={{ bgcolor: '#4527A0' }}>
                <LaptopWindowsIcon />
            </Avatar>
        )
    }

    var onBackClick = () => {
        navigate("/")
    }
 


    var getType = (a) => {

        switch (a) {
            case "monthly": {
                return "WP/"+t("month")
            } 
            case "daily":
                return "WP/"+t("day")
            default:
                return "WP/"+t("day")
        }
    }

    return (
        <div>
            <Box mb={1} mt={1} sx={{
                /*    background:bg_color, */
                display: 'flex',
                color: props.tg.themeParams.text_color ?? '#ffffff',
                flexDirection: 'row',
                alignItems: 'center',

            }}>
                {/* <IconButton aria-label="delete" sx={{ p: 0, mr: 1 }} onClick={onBackClick}>
                    <ArrowBackIcon />
                </IconButton> */}
                <Typography color={text_color} variant="h6" gutterBottom sx={{ m: 0 }}> {t("tariff_desc.tariff_desc_title")}</Typography>
            </Box>
            {
                props.isLoading
                    ? TotalIncomeCard()

                    : data.length == 0
                        ? <List sx={{ minWidth: '100%', maxWidth: 360, color: text_color }}>
                            {
                                [1, 2].map((x: any) => {
                                    /*     const formatDate = Moment(x.Time).format('DD.MM.YYYY')
                                        var sum = x.TotalNow - x.TotalBefore */

                                    return (
                                        <div style={{ width: '100%' }} >
                                            <ListItem
                                                sx={{ mb: 2 }}
                                                style={{ boxShadow: 'none', borderRadius: '12px', width: '100%', color: text_color, background:bg_color }}

                                            >
                                                <ListItemAvatar>
                                                    <Skeleton></Skeleton>
                                                </ListItemAvatar>
                                                <ListItemText primary={<Skeleton></Skeleton>}
                                                    secondaryTypographyProps={{ color: text_color }}
                                                    style={{ color: text_color }} />

                                            </ListItem>
                                            {/*  <Divider variant="inset" component="li" /> */}
                                        </div>
                                    )
                                })
                            }
                        </List>
                        : <List sx={{ minWidth: '100%', maxWidth: 360, color: text_color }}>
                            {
                                data.map((x: any) => {
                                    /*     const formatDate = Moment(x.Time).format('DD.MM.YYYY')
                                        var sum = x.TotalNow - x.TotalBefore */

                                    return (
                                        <div style={{ background:bg_color ,padding:12, borderRadius: 12,marginBottom: 10 }}>
                                            <Grid container spacing={2} mb={2} style={{ alignItems: 'start' }}>
                                                <Grid item xs={8}>
                                                    <Box sx={{ display: 'flex', color: text_color}}>
                                                        <Box pr={2}  sx={{ color: text_color}} >
                                                            {getAvatar(x.Device.String)}
                                                        </Box>

                                                        <Box>
                                                            <Box mb={1} sx={{ color: text_color, display:'flex', alignItems:'center'}} ><b>{x.Device.String == "" ? "Мобильное устройство" : x.Device.String} </b> 
                                                            {
                                                                x.City.String != ""
                                                                ?  <Chip sx={{ml:2}}  style={{background:'#ffc107', color: 'white'}} icon={<StarIcon  className='star_color' fontSize="small"/>} label={x.City.String} />
                                                              /*    <Typography sx={{ml:1}} style={{color:'#4527a0'}}>{x.City.String}</Typography> */
                                                                : ""
                                                            }</Box>
                                                            <Box mb={1} sx={{ color: text_color}} > {x.Protocol.String}</Box>
                                                          {/*   <Box mb={1} sx={{ color: text_color}} > {x.Region.String == "" ? "Россия" : x.Region.String} </Box> */}
                                                          <Box mb={1} sx={{ color: text_color}} > {x.Region.String == "" ? t("countries.RU") : t("countries."+x.Flag.String) } </Box>
                                                            <Box sx={{ color: text_color}} >{x.Count}</Box>
                                                        </Box>
                                                    </Box>
                                                </Grid>

                                                <Grid item xs={4}>
                                                    <Typography textAlign={'right'}> {( x.TariffPrice.Int64 == 0 ? 3 :x.TariffPrice.Int64 ) * x.Count} {getType(x.Type.String)}</Typography>

                                                </Grid>
                                            </Grid>
                                        </div>
                                        /* <ListItem
                                            sx={{ mb: 2 }}
                                            style={{ boxShadow: 'none', borderRadius: '12px', width: '100%', color: text_color, background:bg_color }}
                                            secondaryAction={
                                                <div>{(x.TariffLimit.Int64 == 0 ? 3 : x.TariffPrice.Int64) * x.Count} {getType(x.Type.String)}</div>
                                            }
                                        >
                                            <ListItemAvatar>
                                                {getAvatar(x.Device.String)}
                                            </ListItemAvatar>
                                            <ListItemText primary={<div>{x.Device.String == "" ? "Мобильное устройство" : x.Device.String} {x.Protocol.String} - {x.Region.String == "" ? "Россия" : x.Region.String} </div>} secondary={x.Count}
                                                secondaryTypographyProps={{ color: text_color }}
                                                style={{ color: text_color }} />

                                        </ListItem> */
                                        /*  <Divider variant="inset" component="li" /> */

                                    )
                                })
                            }
                        </List>
            }
        </div>

    );
};
