import { Avatar, Box, FormControlLabel, FormGroup, Grid, Typography } from "@mui/material"
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { text_color } from "../themes/themeTelegram";
export default function RulePage_6(props: any) {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    React.useEffect(() => {
        props.tg.MainButton.setText(t("main_button.go")) //  метод для задания текста
        props.tg.MainButton.show() // показать кнопку 
       
        props.tg.MainButton.onClick(() => {
            navigate("/set_up")
        })
    }, [])

    var onHelpClick = () => {
        props.tg.openTelegramLink("https://t.me/whypnbot")
    }

    return (
        <Box sx={{ mt: 2 }}>
            <Box >

                <Typography variant="body1" sx={{ color: text_color, width: '100%', mt: 2 }}>
                    <b>  </b>
                </Typography>
                <Typography variant="body1" sx={{ color: text_color, width: '100%', mt:2 }}>
                {t("rule_page_6.title_1")} <b>{t("rule_page_6.title_1_1")} </b>{t("rule_page_6.title_1_2")} 
                </Typography>
                <Typography variant="body1" sx={{ color: text_color, width: '100%',mt:2 }}>
                 {t("rule_page_6.title_2_1")} <a onClick={onHelpClick}><u>@whypnbot</u></a>. {t("rule_page_6.title_2_2")}
                 </Typography>

              

            </Box>
        </Box>


    )
}