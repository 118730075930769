import * as React from 'react';
import { styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Button, Grid, IconButton, Skeleton, Typography } from '@mui/material';
/* import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'; */
import { NumericFormat } from 'react-number-format';
import { useNavigate } from 'react-router';
import { current_user, getUrl } from '../../core/UrlService';
import { useLocation } from 'react-router-dom';
import { bg_color } from '../../themes/themeTelegram';
interface ChipData {
    key: number;
    label: number;
}

const ListItem = styled('li')(({ theme }) => ({
    margin: theme.spacing(0.5),
}));

interface State {
    numberformat: string;
}

export default function PayTemp(props: any) {
    const [chipData, setChipData] = React.useState<readonly ChipData[]>([
        { key: 0, label: 3 },
        { key: 1, label: 15 },
        { key: 2, label: 30 },
        { key: 3, label: 45 },

    ]);
    const navigate = useNavigate();
    const [description, setDescription] = React.useState("на 30 дней по текущему тарифу");
    const [sumToPay, setSumToPay] = React.useState();
    const [values, setValues] = React.useState<State>({
        numberformat: "90"
    });
    const [chips, setChips] = React.useState([]);


    /*     React.useEffect(()=>{
            if (location.state.device_id == 3 || location.state.device_id == 4){
    
            }
        },[]) */

    const handleDelete = (chipToDelete: ChipData) => () => {
        setChipData((chips) => chips.filter((chip) => chip.key == chipToDelete.key));
    };

    const onChipClick = (key: any) => {
        setSumToPay(key)

        setValues({
            ...values,
            "numberformat": key
        });
        /*  if (conn.length > 0) */
        setDescription("на " + (key / (3)) + " дней по текущему тарифу")
    }
    var onBackClick = () => {
        navigate("/")
    }
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValues({

            "numberformat": event.target.value
        });

        if (event.target.value != null) {

            var a = event.target.value.toString().replace(' WP', '');
            //setSumToPay(event.target.value as any)
            /*          if (conn.length > 0) */
            setDescription("на " + Math.round(((Number(a)) / (3))) + " дней по текущему тарифу")

            if (location.state.device_id == 3 || location.state.device_id == 4) {
                if (Number(a) < 90) {
                    setDescription("мин. сумма " + (90) + " WP")
                }
            }
            else {
                if (Number(a) < 3 * 3) {
                    setDescription("мин. сумма " + (3 * 3) + " WP")
                }
                else {
                    if (Number(a) > 100000) {
                        setDescription("макс. сумма " + 100000 + " WP")
                    }
                    else {
                        if (a == "")
                            setDescription("мин. сумма " + (3 * 3) + " WP")
                    }
                }
            }
        }
    };


    const setEnabled = () => {

        var res = false;
        var a = values.numberformat.toString().replace(' WP', '');

        if (values.numberformat != null) {

            if (location.state.device_id == 3 || location.state.device_id == 4) {
                res = Number(a) > 90;
            }
            else {
                res = a == "0" || a == "" || Number(a) < 3 * 3 || Number(a) > 3 * 45;
            }

        }


        return res

    }
    const location = useLocation();

    var onPayClick = () => {

        var sum = values.numberformat
        var price = values.numberformat.toString().replace(' WP', '');

        /*    fetch(getUrl("/api/redis", props.tg) + "id=" +
               + (props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id : current_user)
               + '&name='
               + (props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id : "test"
                   + "&flag=" + location.state.id
                   + "&device=" + location.state.device_id
                   + "&conn_name=" + location.state.name
                   + "&protocol=" + location.state.protocol
   
                   + "&from_country=" + location.state.from_country
                   + "&tarif=" + location.state.tarif.Id
   
               ))
               .then(res => res.json())
               .then(
                   (result) => {
    */

        var a = "https://pay.whypn.com/pay?user_id=" + (props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id : current_user)
            + "&amount="
            + price
            + "&key=5&status=1"

        props.tg.openLink(a)
        /*         window.open(a) */
        if (props.tg != null)
            props.tg.close()
        /*   },
          (error) => {
              //setIsLoaded(true);
              //setError(error);
          }
      ) */


    }



    return (
        <Box>

            <Box mt={1} mb={2} sx={{
                display: 'flex',
                color: props.tg.themeParams.text_color ?? '#ffffff',
                flexDirection: 'row',
                alignItems: 'center',


            }}>
                <IconButton aria-label="delete" sx={{ p: 0, mr: 1 }}onClick={onBackClick}>
                    <ArrowBackIcon />
                </IconButton>
                <Typography color={props.tg.themeParams.text_color ?? '#1E88E5'} variant="h6" gutterBottom sx={{ m: 0 }}>Новое подключение</Typography>
            </Box>


            <Box>
                <Typography color={props.tg.themeParams.text_color ?? '#1E88E5'} sx={{ mb: 1 }}>Пополните баланс <b>на любую сумму</b> от 9 WP до 135 WP и вы получите новый QR-код для подключения вашего смартфона к WhyPN</Typography>
            </Box>
            <Box>
                <Typography color={props.tg.themeParams.text_color ?? '#1E88E5'} sx={{ mb: 2 }}>Общий тариф изменится с 0 WP/день на 3 WP/день</Typography>
            </Box>

            <Paper
                sx={{
                    background:  bg_color,
                    display: 'flex',
                    boxShadow: 'none',
                    flexDirection: 'column',

                    borderRadius: '12px',
                    listStyle: 'none',
                    p: 2,
                    m: 0,

                }}
                component="ul"
            >


                <Typography variant="caption" sx={{ color: '#BBBBBB', fontSize: ' 0.675rem', mb: 1 }} >
                    ПОПОЛНИТЬ БАЛАНС
                </Typography>


                <Grid container spacing={2} mb={1}>

                    <Grid item md={3} xs={6} >


                        <div style={{ display: 'flex' }} className="numeric_custom_div">
                            <NumericFormat
                                onChange={handleChange}
                                value={values.numberformat}

                                className="numeric_custom"
                                style={{ width: '100%', display: 'flex', color: props.tg.themeParams.text_color ?? '#1E88E5' }}

                                suffix=" WP"
                            />
                        </div>
                    </Grid>
                    <Grid item md={2} xs={6}  >
                        <Button   variant="contained" aria-label="add to shopping cart" disabled={setEnabled()} sx={{  borderRadius: 0,  p: 1, width: '100%' }}
                            style={{ borderRadius: '10px', textTransform: 'none', boxShadow: 'none' }} onClick={onPayClick}>
                            Пополнить
                            <CreditCardIcon sx={{ ml: 0.5 }} />
                        </Button >
                    </Grid>
                </Grid>
                <Typography variant="caption" style={{ color: '#bbbbbb' }}>{description}</Typography>



                <div style={{ display: 'flex', }}>
                    {chipData.map((data) => {
                        return (

                            <ListItem key={data.key}>


                            </ListItem>
                        );
                    })}
                </div>


            </Paper>
        </Box>
    )
};
