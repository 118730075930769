import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';

import CodeConainer from '../components/Code/CodeContainer';
import CurrentBalance from '../components/Balance/CurrentBalance';
import PayList from '../components/Balance/PayList';

import PropTypes from 'prop-types';
import { useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Button, CardActions, CardContent, Divider, Grid, Menu, MenuItem, Typography } from '@mui/material';


// assets
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import HistoryList from '../components/History/HistoryList';
import { current_user, g_language, getUrl } from '../core/UrlService';
import { useTranslation } from 'react-i18next';



export default function History(props:any) {

    const [data, setData] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(true);

    const {t, i18n} = useTranslation ()

    React.useEffect(() => {
        i18n.changeLanguage(props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.language_code : g_language);

        fetch(getUrl("/api/transactionFree", props.tg) +"userId=" + (props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id :   current_user         ))
        //fetch("http://localhost:5010" + "/api/transactionFree?userId=" + (props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id :   current_user         ))
            .then(res => res.json())
            .then(
                (result) => {
                    setData(result);
                    setIsLoading(false)
                },
                (error) => {
                    //setIsLoaded(true);
                    //setError(error);
                }
            )
    }, [])
    return (
        <div>
           <HistoryList data={data}  tg={props.tg} isLoading = {isLoading} t={t}></HistoryList>
        </div>
    );
}