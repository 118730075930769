import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CodeConainer from '../components/Code/CodeContainer';
import CurrentBalance from '../components/Balance/CurrentBalance';
import PayList from '../components/Balance/PayList';
import Tariff from '../components/Tariff/Tariff';
import { AppBar, Button, CircularProgress, Grid, IconButton, Link, Skeleton, Toolbar } from '@mui/material';
import Settings from '../components/Settings/Settings';
import { useNavigate } from 'react-router';
import Subscription from '../components/New/Subscription';
import NewUserPay from '../components/New/NewUserPay';
import HelpIcon from '@mui/icons-material/Help';
import * as Flags from 'country-flag-icons/react/3x2'
import { current_user, g_language, getUrl } from '../core/UrlService';
import Tariff_v2 from '../components/Tariff/Tariff_v2';
import RefreshIcon from '@mui/icons-material/Refresh';
import MainAd from '../components/Advertising/MainAd';
import NewYearAd from '../components/Advertising/NewYearAd';
import TmAd from '../components/Advertising/TmAd';
import "./style.css"
import { useTranslation } from 'react-i18next';
import NewUserMain from '../pages_v2/NewUserMain';
import { useLocation } from 'react-router-dom';
import Lottie from 'lottie-react';
import light_precomp from "../assets/Duck.json";
import { text_color } from '../themes/themeTelegram';
import { useDispatch, useSelector } from 'react-redux';
import { incrementByAmount, selectCount, setUserInfo } from '../redux/counterSlice';
import { selectUserInfo } from '../redux/counterSlice';

export default function Main(props: any) {

    const { t, i18n } = useTranslation();
    const location = useLocation();
    const conn_ = useSelector(selectCount);
    const userInfo = useSelector(selectUserInfo)
    //const dispatch = useDispatch();

    const navigate = useNavigate();

    //const [data, setData] = React.useState(location.state == null || location.state.conn == null ? null : location.state.data);

    // const [data, setData] = React.useState(location.state == null || location.state.conn == null ? null : location.state.data);
    const [conn, setConn] = React.useState(conn_);
    const [data, setData] = React.useState(userInfo);


    const [isLoading, setIsLoading] = React.useState(conn_.length == 0);
    const [isConnLoading, setIsConnLoading] = React.useState(userInfo == null);
    const [isSub, setIsSub] = React.useState(true);
    const [ip, setIp] = React.useState(null);


    const [chached, setChached] = React.useState(false);
    const [lastRedisKey, setLastRedisKey] = React.useState("");
    const [newStatus, setNewStatus] = React.useState(-1);

    var get_flag = () => {
        if (ip != null && (ip as any).Code != "") {

            var Flag = Flags[(ip as any).Code];
            return <Flag title={(ip as any).Code} style={{ height: 15 }} />
        }

    }



    var repeatCreateConn = () => {
        setIsLoading(true)
        fetch(getUrl("/api/repeat_by_country", props.tg) + "key=" + lastRedisKey)
            //fetch("http://localhost:5010/api/ip")
            .then(res => {

                window.location.reload();

            }
            );

    }


    var onHelpClick = () => {
        navigate("/help")
    }


    const fetchData = async () => {
        const response = await fetch("/api/data");
        const json = await response.json();
        setData(json);
    };
    fetchData();
    const dispatch = useDispatch();

    React.useEffect(() => {

        props.tg.MainButton.hide() // скрыть кнопку
        props.tg.BackButton.hide() // скрыть кнопку

        const fetchData = async () => {
            const response = await fetch("/api/data");
            const json = await response.json();
            setData(json);
        };
        fetchData();


        i18n.changeLanguage(props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.language_code : g_language);
        if (conn.length == 0) {

            setIsLoading(true)
            setIsConnLoading(true)







            fetch(getUrl("/api/connections", props.tg) + "id=" + (props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id : current_user))
                //fetch("http://localhost:5010" + "/api/connections?id=" + (props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id :    current_user       ))
                .then(res => res.json())
                .then(
                    (result) => {


                        setConn(result);


                        fetch(getUrl("/api/user_info", props.tg) + "id=" + (props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id : (props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id : current_user)))
                            .then(res => res.json())
                            .then(
                                (result_) => {
                                    setTimeout(() => {
                                        /*  navigate("/result", { state: { summ: 9 } }) */

                                        setData(result_);
                                        setIsConnLoading(false)
                                        setIsLoading(false)

                                        dispatch(incrementByAmount(result))
                                        dispatch(setUserInfo(result_))
                                    }, 3000);
                                },
                                (error) => {
                                    navigate("/error")
                                }
                            )





                    },
                    (error) => {
                        navigate("/error")
                    }
                )
        }
        else {
            setIsConnLoading(false)
            setIsLoading(false)
        }
    }, [])



    return (
        <div>
            {

                (props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.username : "no auth") == null
                    ? <div>
                        <Typography color={text_color} variant="h6" gutterBottom>{t("other.title_3")}</Typography>
                        <Typography color={text_color} >{t("other.title_4")}</Typography>


                        <iframe width={'100%'} height="315" src="https://www.youtube.com/embed/1GKZ-F1eOMY" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" ></iframe>
                    </div>
                    : <Box>
                        <Box mb={1} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>

                            {/*                             <Typography color={props.tg.themeParams.text_color ?? '#1E88E5'} variant="h6" gutterBottom>{t("private_offcie")}</Typography>
 */}                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div>{get_flag()}</div>
                                {/*   
                                <IconButton aria-label="fingerprint" style={{ color: props.tg.themeParams.text_color ?? '#1E88E5' }} onClick={onHelpClick}>
                                    <HelpIcon />
                                </IconButton> */}
                            </div>

                        </Box>
                        {
                            (isLoading || isConnLoading)
                                ? <Box sx={{ display: 'flex', justifyContent: 'center', pt: 2, flexDirection: 'column', alignItems: 'center' }}>
                                    <Lottie style={{ height: 200 }} animationData={light_precomp} loop={true} />
                                    <Typography /* className="pre_" */ sx={{ mt: 1 }} color={text_color} >I'm thinking... </Typography>

                                    {/*             <CircularProgress sx={{ display: 'flex', justifyContent: 'center', color: props.tg.themeParams.text_color ?? '#1E88E5' }} />
                     */}        </Box>
                                :
                                <Box>


                                    {(conn != null && (conn as any).IsEmpty == true)
                                        /*   ? !isSub */
                                        ?

                                        <>{navigate("/greetings")}</>

                                        :
                                        <div>
                                            <Grid mb={2} container spacing={2}>
                                                <Grid item xs={8} md={10} minHeight={100}>
                                                    <CurrentBalance isLoading={true} d={(data as any)} tg={props.tg}></CurrentBalance>
                                                </Grid>
                                                <Grid item xs={4} md={2} minHeight={100}>
                                                    {/*<Tariff data={(conn as any)?.Connections} tg={props.tg} isLoading={isConnLoading}></Tariff> */}
                                                    <Tariff_v2 data={(conn as any)?.Connections} tg={props.tg} isLoading={isConnLoading}></Tariff_v2>
                                                </Grid>
                                            </Grid>

                                            <Box>
                                                <Box>
                                                    <PayList tg={props.tg} tarif={(conn as any).Connections}></PayList>
                                                </Box>

                                                <Box className={"rrr"}>
                                                    {/*  <TmAd  tg={props.tg}  info={data}  conn={(conn as any)?.Connections}></TmAd> */}
                                                    {/*  <NewYearAd tg={props.tg}  info={props.data}  conn={(props.conn as any)?.Connections}></NewYearAd> */}
                                                    <MainAd className={"rrr_"} style={{ paddingRight: '16px', paddingLeft: '16px' }} tg={props.tg} info={data} conn={(conn as any)?.Connections}></MainAd>
                                                    {/*  <PcAd tg={props.tg}  info={data}  conn={(conn as any)?.Connections}></PcAd> */}
                                                </Box>
                                                <Box mb={2}>
                                                    <CodeConainer
                                                        info={data}
                                                        tg={props.tg}
                                                        data={(conn as any)?.Connections}
                                                        balance={(data as any) != null && (data as any).length > 0 ? (data as any)[0].TotalNow : 0}
                                                    /* isLoading={isConnLoading} */></CodeConainer>
                                                </Box>
                                            </Box>
                                        </div>
                                    }
                                    {/* <Box mb={2}>
                                        <Settings tg={props.tg}></Settings>
                                    </Box> */}


                                </Box>


                        }


                    </Box >
            }


            {/*   <AppBar position="fixed" sx={{ top: 'auto', bottom: 0, background: 'white', boxShadow: 'none' }}>
                <Toolbar>

                    <Box className="basket-button_bottom"  style={{ color: 'black', width: '100%' }}>

                        <Box style={{ display: 'flex' }}>
                            <Typography >
                                30-40 мин
                            </Typography>
                        </Box>

                        <Box style={{ display: 'flex' }}>
                            <Typography >
                                Оформить
                            </Typography>
                        </Box>
                        <Box style={{ display: 'flex', alignItems: 'center' }}>
                         
                            <div>
                                <div style={{  color: 'black' }} className="basket-button_chip">
                                    {200 + " WP"}
                                </div>
                            </div>
                        </Box>

                    
                    </Box>
              
                </Toolbar>
            </AppBar> */}

        </div>
    );
}


