import * as React from 'react';
import Typography from '@mui/material/Typography';
import { Box, Button, Grid, IconButton, Link, TextField } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { g_language, getUrl } from '../core/UrlService';
import i18n from '../i18n';
import { bg_color, text_color } from '../themes/themeTelegram';

export default function SendingMessage(props: any) {

    const [data, setData] = React.useState([]);
    const [code, setCode] = React.useState("");

    const navigate = useNavigate();
    const params = useParams();

    var onLkClick = () => {
        props.tg.openLink("https://lk.whypn.com/login")
    }

    var onHelpClick = () => {
        props.tg.openTelegramLink("https://t.me/whypnbot")
    }


    React.useEffect(() => {
        i18n.changeLanguage(props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.language_code : g_language);
    }, [])
    
    var onOkClick = () => {

        navigate("/")
        /*     fetch(getUrl("/api/approve_email", props.tg) + "phone=" + mobile + "&user=" + (props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id : current_user))
            //fetch("http://localhost:5010" + "/api/available")
            .then(res => {
               
              navigate("/sendig_message")
            }) */
    }


    const handleChange1 =
        (prop: keyof any) => (event: React.ChangeEvent<HTMLInputElement>) => {
            setCode(event.target.value);
            //setIsButtonAvailable(m.indexOf('_') != -1 || m == "")
            /*  if (m.indexOf('_') != -1) {
                 setResponceError("Номер указан неверно")
             } */

        };

        const location = useLocation();
        
    return (
        <Box mb={2}/* style={{ background:  bg_color }} */>
            <Box mb={2} mt={1} sx={{
                /*    background:  bg_color, */
                display: 'flex',
                color: text_color,
                flexDirection: 'row',
                alignItems: 'center',

            }}>
                {/*  <IconButton aria-label="delete" sx={{ p: 0, mr: 1 }} style={{ color: props.tg.themeParams.text_color ?? '#1E88E5' }} onClick={onBackClick}>
                    <ArrowBackIcon />
                </IconButton> */}
                <Typography color={text_color} variant="h6" gutterBottom sx={{ m: 0 }}></Typography>
            </Box>
            <Box style={{ background: bg_color, borderRadius: '12px', boxShadow: 'none', color: text_color }} sx={{ p: 2 }}>


                {/*    <Box mb={3}>
                    Текущий общий тариф пользователя WhyPN состоит из суммы тарифов всех подключений пользователя
                </Box> */}

                <Box mb={1}>

                    {/*   <Typography>  Номер добавлен к </Typography> */}
                    <Box>
                        <Typography  > Мобильный номер успешно привязан к вашему аккаунту WhyPN
                        </Typography>
                    </Box>

                    <Box>
                        <Typography  > Спасибо, что вы с нами </Typography>
                    </Box>


                    <Box>
                        <Typography  > Ваш номер: {location.state.phone} </Typography>
                    </Box>

                    <Box>
                        <Typography  > Адрес для нового Личного кабинета: <Typography onClick={onLkClick}> lk.whypn.com </Typography></Typography>
                    </Box>
                    <Box>
                        <Typography  >Остались вопросы? Напишите в поддержку нашего сервиса <Typography onClick={onHelpClick}></Typography>@whypnbot</Typography> 
                    </Box>

                    {/*   <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Код потверждения"
                        name="email"
                        value={code}
                        autoComplete="email"
                        autoFocus
                        onChange={handleChange1('mobile')}
                    /> */}
                    <Button   variant="contained" aria-label="add to shopping cart" sx={{  borderRadius: 0, p: 1, width: '100%', mt: 1 }}
                        style={{ borderRadius: '10px', textTransform: 'none', boxShadow: 'none' }} onClick={onOkClick}>
                        Вперед!
                    </Button >

                </Box>
            </Box>
        
        </Box >
    );
}