import * as React from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import RestoreIcon from '@mui/icons-material/Restore';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ArchiveIcon from '@mui/icons-material/Archive';
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import zIndex from '@mui/material/styles/zIndex';
import FolderCopyIcon from '@mui/icons-material/FolderCopy';
import PersonIcon from '@mui/icons-material/Person';
import SpeedIcon from '@mui/icons-material/Speed';
import { Link, useLocation } from 'react-router-dom';
import GroupIcon from '@mui/icons-material/Group';
import i18n from '../i18n';
import { g_language } from './UrlService';
import { t } from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { selectCount, selectCurrent, setCurrent } from '../redux/counterSlice';
import { button_color, secondary_bg_color, text_color } from '../themes/themeTelegram';
import { Typography } from '@mui/material';

const withouSidebarRoutes = [

  '/rule_page_1',
  '/rule_page_2',
  '/rule_page_3',
  '/rule_page_4',
  '/rule_page_5',
  '/rule_page_6',

  '/set_up',
  '/greetings',
  '/result',
  '/',
  '/what_is_wp',
  '/pay_first',
  '/pay_detail_first',
  '/history'
];


export default function BottomNavbar(props: any) {
  const dispatch = useDispatch();
  const val = useSelector(selectCurrent);
  const count = useSelector(selectCount);
  const [value, setValue] = React.useState(val);
  const ref = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    i18n.changeLanguage(props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.language_code : g_language);

  }, [])

  /* const { pathname } = useLocation();
  if (withouSidebarRoutes.some((item) => pathname === item)) {
     
    return null
  }
  else {
 */

  return (
    <Box ref={ref} >

      <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 99999 }} elevation={3}>
        <BottomNavigation
          showLabels
         
          value={value}
          onChange={(event, newValue) => {

            dispatch(setCurrent(newValue))
            setValue(newValue)

          }}
          sx={{ background: secondary_bg_color }}
        >
          <BottomNavigationAction
          
            value={0}
            component={Link}
            to="/main"
            style={{color: value == 0 ? button_color :text_color}}
            
            label={<Typography variant='caption'>{t("bottom_nav.my_whypn")}</Typography>}
            icon={<FolderCopyIcon sx={{ pb: 0.4, height:20 }} />}
          />
          <BottomNavigationAction
            value={1}
            component={Link}
            to="/friends"
            style={{color: value == 1 ? button_color :text_color}}
            label={<Typography variant='caption'>{t("bottom_nav.friends")}</Typography>}
            icon={<GroupIcon sx={{ pb: 0.4 , height:20 }} />}
          />
          <BottomNavigationAction
            value={2}
            component={Link}
            to="/nav_list"
            style={{color: value == 2 ? button_color :text_color}}
            label={<Typography variant='caption'>{t("bottom_nav.personal")}</Typography>}
            icon={<PersonIcon sx={{ pb: 0.4 , height:20 }} />}
          />
        </BottomNavigation>
      </Paper>
    </Box>
  );
}




