import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CodeList from '../components/Code/CodeList';
import { Box, Button, IconButton, Skeleton, Stack } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import PayTemp from '../components/Temp/PayTemp';
import { connect } from 'http2';
import CreateCode from '../components/Connection/CreateCode';
import { current_user, g_language, getUrl } from '../core/UrlService';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import { useTranslation } from 'react-i18next';
import { bg_color, text_color } from '../themes/themeTelegram';

export default function NewCode(props: any) {

    const location = useLocation();
    const [data, setData] = React.useState(location.state.conn);
    const [userInfo, setUserInfo] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false);
    const [users, setUser] = React.useState(false);

    const [isConnLoading, setIsConnLoading] = React.useState(true);
    const [isUserInfoLoading, setIsUserInfoLoading] = React.useState(true);
    const [isUsersTmLoading, setIsUsersTmoading] = React.useState(true);
    const navigate = useNavigate();
    const params = useParams();



    var onPayClick = () => {

        window.open("https://pay.whypn.com/pay?user_id=" + (props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id : current_user) + "&amount=" + 3 + "&key=5&status=1", "_blank")
    }



    React.useEffect(() => {


        props.tg.BackButton.show()
        props.tg.BackButton.onClick(() => {
            navigate("/main")
        })

        i18n.changeLanguage(props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.language_code : g_language);

        fetch(getUrl("/api/availavle_tm_users", props.tg))
            //fetch("http://localhost:5010" + "/api/connections?id=" + (props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id :    current_user       ))
            .then(res => res.json())
            .then(
                (result) => {

                    var current = props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id : current_user
                    var res = result.includes(current + "")
                    setUser(res)
                    setIsUsersTmoading(false)
                },
                (error) => {

                }
            )

        /*       fetch(getUrl("/api/connections", props.tg) + "id=" + (props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id : current_user))
                  //fetch("http://localhost:5010" + "/api/connections?id=" + (props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id :    current_user       ))
                  .then(res => res.json())
                  .then(
                      (result) => {
                          setIsConnLoading(false)
                          setData(result.Connections);
                      },
                      (error) => {
      
                      }
                  ) */

        fetch(getUrl("/api/user_info", props.tg) + "id=" + (props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id : (props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id : current_user)))
            //fetch("http://localhost:5010" + "/api/user_info?id=" + (props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id : (props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id :    current_user       )))
            .then(res => res.json())
            .then(
                (result) => {
                    setIsUserInfoLoading(false)
                    setUserInfo(result);
                },
                (error) => {
                }
            )


    }, [])

    var onCreateCodeClick = () => {
        fetch(getUrl("/api/transactionFree", props.tg) + 'id=' + (props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id : current_user) + '&name=' + "test")
            //fetch("http://localhost:5010" + '/api/update?id=' + (props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id :    current_user       ) + '&name=' + "test")
            .then(res =>
                navigate("/")
            );
    }

    var onBackClick = () => {
        navigate("/")
    }



    /*  var getCurrentTariff = () => {
         return
     } */

    const { t, i18n } = useTranslation()

    return (
        /* <CreateCode tg={props.tg}></CreateCode> */
        <Box>
            {
               /*  isConnLoading || */ isUserInfoLoading /* || isUsersTmLoading */
                    ?
                    <Box>
                        <Box mb={2} mt={1} sx={{

                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center'
                        }}>
                            {/* <IconButton aria-label="delete" sx={{ p: 0, mr: 1 }}  onClick={onBackClick}>
                                <ArrowBackIcon />
                            </IconButton> */}
                            <Typography color={text_color} variant="h6" gutterBottom sx={{ m: 0, width: '100%' }}> <Skeleton /></Typography>
                        </Box>
                        <Box style={{ background:  bg_color, borderRadius: '12px', boxShadow: 'none' }} sx={{ p: 2 }}>
                            <Box mb={1} style={{ color: text_color }}>
                                <Skeleton height={40}></Skeleton>
                            </Box>
                            <Box style={{ color: text_color }}>
                                <Skeleton height={40}></Skeleton>
                            </Box>
                        </Box>

                    </Box>
                    :
                    data.length == 0
                        ? <PayTemp tg={props.tg}></PayTemp>
                        :
                        (userInfo[0] as any).TotalNow >= 3 ?
                            <Box>
                                <Box /* mb={2} */ mt={1} sx={{

                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center'
                                }}>
                                   {/*  <IconButton aria-label="delete" sx={{ p: 0, mr: 1 }} onClick={onBackClick}>
                                        <ArrowBackIcon />
                                    </IconButton> */}
                                    <Typography color={text_color} variant="h6" gutterBottom sx={{ m: 0 }}>{t("new_page.getting_connected")}</Typography>
                                </Box>
                                {/*  <Box style={{ background:  bg_color, borderRadius: '12px', boxShadow: 'none' }} sx={{ p: 2 }}>
                                    <Box style={{ color: text_color }}>
                                        Выбранный тариф:
                                    </Box>
                                </Box> */}
                                {/*   <Box style={{ background:  bg_color, borderRadius: '12px', boxShadow: 'none' }} sx={{ p: 2, mt: 2, mb: 2 }}> */}
                                <CreateCode t={t} tg={props.tg} users={true}></CreateCode>
                                {/*  </Box> */}
                                {/* 
                                <Box mt={3}>
                                    {
                                        isLoading
                                            ?
                                            <LoadingButton style={{
                                                width: '100%',
                                                background:  bg_color,
                                                boxShadow: 'none',
                                                borderRadius: '12px'
                                            }} loading variant="outlined">
                                                Загрузка
                                            </LoadingButton>
                                            : <Button style={{
                                                width: '100%',
                                                background:  bg_color,
                                                color: text_color,
                                                boxShadow: 'none',
                                                borderRadius: '12px'
                                            }} variant="contained" onClick={() => { setIsLoading(true); onCreateCodeClick() }}>Да, продолжить</Button>
                                    }

                                </Box> */}

                            </Box >
                            : <Box>
                                <Box mb={2} mt={1} sx={{

                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center'
                                }}>
                               {/*      <IconButton aria-label="delete" sx={{ p: 0, mr: 1 }}onClick={onBackClick}>
                                        <ArrowBackIcon />
                                    </IconButton> */}
                                    <Typography color={text_color} variant="h6" gutterBottom sx={{ m: 0 }}> {t("new.title_1")}</Typography>
                                </Box>
                                <Box style={{ background: bg_color, borderRadius: '12px', boxShadow: 'none' }} sx={{ p: 2 }}>
                                    <Box mb={3} style={{ color: text_color }}>
                                    {t("other.lower_balance")}
                                    </Box>
                                    <Box style={{ color: text_color}}>
                                    {t("other.title_2")}: 3 WP
                                    </Box>
                                </Box>
                                <Box mt={3}>



                                    <Button   variant="contained" aria-label="add to shopping cart" sx={{ borderRadius: 0,p: 1, width: '100%' }}
                                        style={{ borderRadius: '10px', textTransform: 'none', boxShadow: 'none' }} onClick={onPayClick}>
                                        {t("new.top_up")}
                                        <CreditCardIcon sx={{ ml: 0.5 }} />
                                    </Button >


                                    {/*    <Button style={{
                                        width: '100%',
                                        background:  bg_color,
                                        color: text_color,
                                        boxShadow: 'none',
                                        borderRadius: '12px'
                                    }} variant="contained" onClick={() => { navigate("/") }}>Понятно</Button>
 */}



                                </Box>

                            </Box >
            }
        </Box>
    );
}