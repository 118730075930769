import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Alert, AlertTitle, Box, Skeleton, Typography } from '@mui/material';

export default function UpdateLimitDialog(props: any) {
  const [open, setOpen] = React.useState(false);

  /*   const [sup, setOpen] = React.useState(false); */

  const handleClickOpen = () => {
     
    props.handleClickOpen();

  };


  const handleApprove = () => {

    props.handleApprove()
  };
  const handleClose = () => {

    props.handleClose();
  };


  React.useEffect(() => { setOpen(props.dialog_open) }, [props.dialog_open]);
  return (


    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        style: {
          backgroundColor: props.tg != undefined && props.tg.colorScheme == 'dark' ? '#323232' : 'white'


        },
      }}
    >
      <DialogTitle id="alert-dialog-title" style={{ color: props.tg.themeParams.text_color ?? 'black' }}>
        {"Продлить подключение"}
      </DialogTitle>
      <DialogContent>
        {
          props.isLoading
            ? <Typography variant="h4" sx={{ width: '100%', flexShrink: 0 }} style={{ width: '100%;', color: props.tg.themeParams.text_color ?? 'black' }}><Skeleton></Skeleton></Typography>
            : <DialogContentText id="alert-dialog-description" style={{ color: props.tg.themeParams.text_color ?? 'black' }}>
              {/* {props.text} */}
              <Box>Лимит подключения будет обновлен на  <b>{props.limit}</b> Гб</Box>
              <Box>Будет списано <b>{props.sum}</b> WP</Box>
              <Alert severity="info" sx={{mt:1}} icon={false}>
                {/* <AlertTitle>Совет</AlertTitle> */}
                <Typography>После сброса трафика <strong>выключите и включите заново</strong> WhyPN через приложение Outline</Typography>
            
              </Alert>
              {/*  Выбранное подключение будет удалено. */}

            </DialogContentText>
        }
      </DialogContent>

      {
        !props.isOk
          ? <DialogActions>

            <Button onClick={handleClose} disabled={props.isLoading} >Отменить</Button>
            <Button onClick={handleApprove} disabled={props.isLoading} >
              Да, продолжить
            </Button>
          </DialogActions>
          : <DialogActions>

            <Button onClick={handleApprove} disabled={props.isLoading} >Отлично</Button>

          </DialogActions>
      }

    </Dialog>

  );
}