import react, * as React from 'react';
import Typography from '@mui/material/Typography';
import { Box, Button, Grid, IconButton, Link } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import axios from 'axios';
import { data } from './test';
import './style.css';
import { url } from 'inspector';
import { base_url } from '../../core/UrlService';
import IframeResizer from 'iframe-resizer-react';

export default function Instructions(props: any) {

    const [data_, setData] = React.useState<any>();
    const navigate = useNavigate();
    const params = useParams();

    const location = useLocation();


    var onCreateCodeClick = () => {
        /*    fetch("http://localhost:5010" + '/api/update?id=' + (props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id :   current_user         ) + '&name=' + "test")
               .then(res =>
                   navigate("history")
               ); */
        window.open('https://donate.qiwi.com/payin/WhyPN', '_blank')
    }

    var onBackClick = () => {
        navigate("/")
    }
    var onAppleClick = () => {
        window.open("https://telegra.ph/WhyPN-dlya-iPhone-i-iPad-08-31", "_blank")
    }

    var onOutlineClick = () => {
        window.open("https://telegra.ph/WhyPN-ShadowSocks-08-16", "_blank")
    }

    var onAndroidClick = () => {
        window.open("https://telegra.ph/WhyPN--Android-08-31", "_blank")
    }


    async function fetchData() {
        try {
             
            const output = await axios(
                base_url + "/" + (location.state.instruction == "" ? "uni_outline" : location.state.instruction ) + ".html");


            var t = output.data.replace(new RegExp("href=\"https://whyrl"), "onClick='window.Telegram.WebApp.openLink(\"https://whyrl")
            var tr = t.replace(">whyrl.me", "); return false;'> whyrl.me")

          
            
            while (tr.includes("href=\"https://whyrl")) {
                var a = tr
                a = a.replace("href=\"https://whyrl", "onClick='window.Telegram.WebApp.openLink(\"https://whyrl")
                a = a.replace(">whyrl.me", "); return false;'> whyrl.me")
                tr = a
            }
            var trr = tr.replace("href=\"https://t.me", "onClick='window.Telegram.WebApp.openLink(\"https://t.me")
            var trrr = trr.replace(">@whypnbot", "); return false;'>@whypnbot")
            setData(trrr)
          
            /*    setData(output.data) */
            console.log(output);
        } catch (e) {
            console.error(e);
        }

    }

    React.useEffect(() => {
             fetchData()
   /*      var t = data.replace("href=\"http://whyrl", "onClick='window.Telegram.WebApp.openLink(\"http://whyrl")
        var tr = t.replace(">whyrl.me", "); return false;'> whyrl.me")
         
        while (tr.includes("href=\"http://whyrl")) {
            var a = tr
            a = a.replace("href=\"http://whyrl", "onClick='window.Telegram.WebApp.openLink(\"http://whyrl")
            a = a.replace(">whyrl.me", "); return false;'>whyrl.me")
            tr = a
        }
        var trr = tr.replace("href=\"https://t.me", "onClick='window.Telegram.WebApp.openLink(\"https://t.me")
        var trrr = trr.replace(">@whypnbot", "); return false;'>@whypnbot")
        setData(trrr) */


        //setData(data)
    }, [])

    const cssAppliedContent = body => `
    <div>
      <style>
      h6{
        padding-top: 0px !important;
        font-size: 1.25rem !important;
      }
        body {
          padding: 0 !important;
        max-width: 100% !important;
        background: white !important;
        
        }

        .css-14di9ns-MuiTypography-root{
            font-size: 1.5rem !important; 
        }
      </style>
      ${body}
    <div>
    `;


    function ttt(t) {
         
    }
    const [frameHeight, setFrameHeight] = React.useState()


    return (
        <Box /* style={{ background:  bg_color }} */>
            <Box mb={2} mt={1} sx={{
                /*    background:  bg_color, */
                display: 'flex',
                /*    color: props.tg.themeParams.text_color ?? '#ffffff', */
                color: '#000000',
                flexDirection: 'row',
                alignItems: 'center',

            }}>
                <IconButton aria-label="delete" sx={{ p: 0, mr: 1 }} style={{  }} onClick={onBackClick}>
                    <ArrowBackIcon />
                </IconButton>
                <Typography color={'#000000'} variant="h6" gutterBottom sx={{ m: 0 }}> Инструкция</Typography>
            </Box>
            <Box sx={{ p: 2, background: props.tg != undefined && props.tg.colorScheme == 'dark' ? 'rgb(255 255 255 / 8%)' : '000000', borderRadius: '12px', mb: 2 }}>
                {/*      <a onClick={() => { ttt("https://t.me/whypnbot") }}>whyrl.me/Emvb</a>
                <a onClick={() => { props.tg.openLink("https://whyrl.me/Emvb"); props.tg.close() }}>test_1_link</a>
                <a onClick={() => { props.tg.openLink("https://whyrl.me/75Dl"); props.tg.close() }}>test_2_link</a>
                <a onClick={() => { props.tg.openLink("https://whyrl.me/LZqa"); props.tg.close() }}>test_3_link</a> */}
                {/*     <iframe
      ref={ref as any}
      onLoad={onLoad}
      id="myFrame"
      src={base_url_instructions+"/"+location.state.instruction+".html"}
      width="100%"
      height={height}
      scrolling="no"
      frameBorder="0"
      style={{
        maxWidth: 640,
        width: "100%",
        overflow: "auto",
      }}
    ></iframe> */}


                {/*    <IframeResizer
                heightCalculationMethod="max"
                    log
                    src={base_url_instructions + "/" + location.state.instruction + ".html"}
                    style={{ width: '1px', minWidth: '100%', minHeight:'100%' }}
                />
 */}
                {/*    {
                    location.state.instruction == null
                        ? <Box className="iframe-container" >
                            <iframe src={base_url + "/" + "uni_outline" + ".html"} width={"100%"} height={"100vh"} ></iframe>
                        </Box>
                        : <Box className="iframe-container" >
                            <iframe src={base_url + "/" + location.state.instruction + ".html"} width={"100%"} height={"100vh"} ></iframe>
                        </Box>
                } */}

                {/* 
                  <div
                   
                 
                    dangerouslySetInnerHTML={{
                        __html: cssAppliedContent(data),
                    }}
                /> */}


                <div className='instruction_block'
                    dangerouslySetInnerHTML={{ __html: cssAppliedContent(data_) }}

                />


            </Box>
        </Box >
    );
} 