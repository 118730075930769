export var getHtmlToRedirect = (ss: string) => {
    return `<!DOCTYPE html>
    <html lang="en">
    <head>
        <meta charset="utf-8" />
        <meta http-equiv="refresh" content="0; url=`+ss+`" />
    
        <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css" integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossorigin="anonymous">
    
    <script>
    function getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    
        alert(userAgent)
        // Windows Phone must come first because its UA also contains "Android"
        if (/windows phone/i.test(userAgent)) {
            return "Windows Phone";
        }
    
        if (/android/i.test(userAgent)) {
            return "Android";
        }
    
        // iOS detection from: http://stackoverflow.com/a/9039885/177710
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            return "iOS";
        }
    
    
        return "unknown";
        
        
    }</script>
    
    <script>
    function DetectAndServe(){
        let os = getMobileOperatingSystem();
        if (os == "Android") {
            window.location.href = "https://play.google.com/store/apps/details?id=org.outline.android.client&hl=en&gl=US"; 
        } else if (os == "iOS") {
            window.location.href = "https://apps.apple.com/ru/app/outline-app/id1356177741";
        } else if (os == "Windows Phone") {
            window.location.href = "http://www.WindowsPhoneexample.com";
        } else {
            window.location.href = "https://whypn.com";
        }
    }
    </script>
    </head>
    <body onload="setTimeout('DetectAndServe()', 4000);">
        <div class="container">
            <button class="btn btn-primary" type="button" disabled>
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              WhyPN загружает данные...
            </button>
          </div>
    </body>
    </html>`
    
}