import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, LinearProgress, Skeleton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { g_language } from '../../../core/UrlService';
import { text_color } from '../../../themes/themeTelegram';

export default function DeleteDialog(props: any) {
  const [open, setOpen] = React.useState(false);

  const handleApprove = () => {
    props.handleApprove();
  };

  const handleClose = () => {

    props.onDeleteClickClose();
  };

  const { t, i18n } = useTranslation()
  React.useEffect(() => { setOpen(props.dialog_open) }, [props.dialog_open]);

  React.useEffect(() => {
    i18n.changeLanguage(props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.language_code : g_language);
}, [])

  return (


    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        style: {
          backgroundColor: props.tg != undefined && props.tg.colorScheme == 'dark' ? '#323232' : 'white'


        },
      }}
    >
      <DialogTitle id="alert-dialog-title" style={{ color: props.tg.themeParams.text_color ?? 'black' }}>
      <Typography variant='h6'>{t("delete.title_1")} </Typography> 
      </DialogTitle>
      <DialogContent>
        {
          props.isLoading || props.isDeleteLoading
            ? <Typography variant="h4" sx={{ width: '100%', flexShrink: 0 }} style={{ width: '100%;', color: props.tg.themeParams.text_color ?? 'black' }}><Skeleton></Skeleton></Typography>
            : <DialogContentText id="alert-dialog-description" style={{ color: props.tg.themeParams.text_color ?? 'black' }}>
              {t("delete.quest")}
              {/*  Выбранное подключение будет удалено.
          Вместо него будет создано новое подключение протокола Outline */}
            </DialogContentText>
        }
      </DialogContent>



      {
        props.isDeleteLoading
          ?
          <DialogActions>
            <Box sx={{ width: '100%', pb:1 }}>
              <LinearProgress />
            </Box>
          </DialogActions>
          :
          <DialogActions>
            <Button onClick={handleClose}   style={{ borderRadius: '10px', textTransform: 'none', boxShadow: 'none', background: "transparent", color: text_color }} disabled={props.isLoading} >{t("delete.no")}</Button>
            <Button onClick={handleApprove} disabled={props.isLoading}> {t("delete.yes")}</Button>
          </DialogActions>
      }






    </Dialog>

  );
}