import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import InputMask from "react-input-mask";
import { current_user, g_language, getUrl } from '../../core/UrlService';
import { useTranslation } from 'react-i18next';
import { bg_color, text_color } from '../../themes/themeTelegram';

function Copyright(props: any) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function UserInfoForm(props: any) {

  const { t, i18n } = useTranslation()

  const [isLoading, setIsLoading] = React.useState(false);
  React.useEffect(() => {

    props.tg.BackButton.show()
    props.tg.BackButton.onClick(() => {
      navigate("/nav_list")
    })
  

    i18n.changeLanguage(props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.language_code : g_language);

    setIsLoading(true)
    fetch(getUrl("/api/user", props.tg) + "user=" + (props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id : current_user))
      //fetch("http://localhost:5010" + "/api/sub?id=" + (props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id :    current_user       ))
      .then(res => res.json())
      .then(
        (result) => {
        

          // setEmail(result.Email)
          setMobile(result.Phone.String)
          setIsLoading(false)
        },
        (error) => {
          //setIsLoaded(true);
          //setError(error);
        }
      )
  }, [])

  const [mobile, setMobile] = React.useState("");
  const [email, setEmail] = React.useState("");

  const navigate = useNavigate();

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get('email'),
      password: data.get('password'),
    });
  };


  var onBackClick = () => {
    navigate("/")
  }

  const handleChange1 =
    (prop: keyof any) => (event: React.ChangeEvent<HTMLInputElement>) => {



      var m = event.target.value.replace('+', '').replace(/\s+/g, "").replace('(', '').replace(')', '')
      setMobile(m);

      //setIsButtonAvailable(m.indexOf('_') != -1 || m == "")
      /*  if (m.indexOf('_') != -1) {
           setResponceError("Номер указан неверно")
       } */

    };

  var onApproveClick = () => {

    if (props.tg.initDataUnsafe.user != undefined) {
      props.tg.requestContact((a: any, b: any) => {

        if (a) {
          var phone = b.responseUnsafe.contact.phone_number
          fetch(getUrl("/api/update_user", props.tg) + "phone=" + phone + "&user=" + (props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id : current_user))
            //fetch("http://localhost:5010" + "/api/available")
            .then(res => {

              navigate("/sendig_message", { state: { phone: mobile } })
            })
        }


      })
    }
    else {
      fetch(getUrl("/api/update_user", props.tg) + "phone=" + 7 + "&user=" + (props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id : current_user))
        //fetch("http://localhost:5010" + "/api/available")
        .then(res => {

          navigate("/sendig_message", { state: { phone: mobile } })
        })
    }
  }
  /*  fetch(getUrl("/api/approve_email", props.tg) + "phone=" + mobile + "&user=" + (props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id : current_user))
     //fetch("http://localhost:5010" + "/api/available")
     .then(res => {
        
       navigate("/sendig_message")
     }) */

  var onLkClick = () => {
    props.tg.openLink("https://lk.whypn.com/login")
  }

  var onHelpClick = () => {
    props.tg.openTelegramLink("https://t.me/whypnbot")
  }

  return (
    /*     <ThemeProvider theme={defaultTheme}>
    
          <CssBaseline />
    
          {
            mobile == "" || email == ""
              ?
              <Box
    
              >
    
                <Box mb={2} mt={1} sx={{
    
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center'
                }}>
                  <IconButton aria-label="delete" sx={{ p: 0, mr: 1 }} style={{ color: text_color }} onClick={onBackClick}>
                    <ArrowBackIcon />
                  </IconButton>
                  <Typography color={text_color} variant="h6" gutterBottom sx={{ m: 0 }}>Главное меню</Typography>
                </Box>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Почтовый адрес"
                    name="email"
                    autoComplete="email"
                    autoFocus
                  />
                 
    
                  <InputMask
                    mask="+7 (999) 999 9999"
                    value={mobile}
                    maskChar={"_"}
                    onChange={handleChange1('mobile')}
    
    
    
                  >
                    {() => <TextField
                      label="Номер телефона *"
                     
                      margin="normal"
                      type="text"
                      style={{ width: '100%' }}
                      sx={{ mt: 1 }}
                      variant="outlined"
                    />}
                  </InputMask>
                  <Button   variant="contained" aria-label="add to shopping cart" sx={{ color: 'white', borderRadius: 0, backgroundColor: '#ffc107', p: 1, width: '100%', mt: 1 }}
                    style={{ borderRadius: '10px', textTransform: 'none', boxShadow: 'none' }} onClick={onApproveClick}>
                    Потвердить
                  </Button >
                </Box>
    
    
    
              </Box>
              :
              <Box>
                <Typography>{mobile}</Typography>
                <Typography>{email}</Typography>
              </Box>
          }
        </ThemeProvider> */

    <Box /* style={{ background:  bg_color }} */>
      {
        isLoading
        ? <></>
        : mobile == "" ?
          <Box>
            <Box mb={2} mt={1} sx={{
              /*    background:  bg_color, */
              display: 'flex',
              color: text_color,
              flexDirection: 'row',
              alignItems: 'center',

            }}>

              {/* <IconButton aria-label="delete" sx={{ p: 0, mr: 1 }} style={{ color: text_color }} onClick={onBackClick}>
                <ArrowBackIcon />
              </IconButton> */}
              <Typography color={text_color} variant="h6" gutterBottom sx={{ m: 0 }}>{t("attach.attach_succes_title")}</Typography>
            </Box>
            <Box style={{ background:  bg_color, borderRadius: '12px', boxShadow: 'none', color: text_color }} sx={{ p: 2 }}>

              <Typography sx={{ mb: 2 }} fontWeight={700}>
                {t("attach.attach_desc")}

              </Typography>
              <Typography sx={{ mb: 2 }}>
                {t("attach.attach_succes_desc")}              </Typography>
              {/*      <Typography sx={{ mb: 2 }}>
                Чтобы вы могли получить доступ к личному кабинету WhyPN не только через Telegram, пожалуйста, привяжите свой номер телефона к учетной записи WhyPN. Так у нас будет больше возможностей противостоять блокировкам и предоставлять для вас свободный Интернет при любых условиях
              </Typography>
 */}
              {/*   <Typography sx={{ mb: 2 }}>
                За привязку номера к учетонй записи до 23 февраля вы получите 1 месяц бесплатного подключения 🙏
              </Typography> */}
              <Typography sx={{ mb: 2 }} fontWeight={700}>

                {t("attach.attach_thanks")}
              </Typography>

              <Button   variant="contained" aria-label="add to shopping cart" sx={{borderRadius: 0, p: 1, width: '100%', mt: 1 }}
                style={{ borderRadius: '10px', textTransform: 'none', boxShadow: 'none' }} onClick={onApproveClick}>
                {t("attach.attach_btn")}
              </Button >
            </Box>
          </Box>
          : <Box>
            <Box mb={2} mt={1} sx={{
              /*    background:  bg_color, */
              display: 'flex',
              color: text_color,
              flexDirection: 'row',
              alignItems: 'center',

            }}>

              {/*     <IconButton aria-label="delete" sx={{ p: 0, mr: 1 }} style={{ color: text_color }} onClick={onBackClick}>
                <ArrowBackIcon />
              </IconButton> */}
              <Typography color={text_color} variant="h6" gutterBottom sx={{ m: 0 }}>{t("attach.attach_succes_title")}</Typography>
            </Box>
            <Box style={{ background:  bg_color, borderRadius: '12px', boxShadow: 'none' }} sx={{ p: 2 }}
            >
              <Typography sx={{ mb: 2 }} color={text_color}>
                {t("attach.attach_succes_subtitle")}
              </Typography>
              <Typography sx={{ mb: 2 }} color={text_color}>
                {t("attach.attach_succes_thsnks")} 🤝
              </Typography>
              <Typography sx={{ mb: 2 }} color={text_color} >
                {t("attach.attach_succes_number")}: +{mobile}
              </Typography>
              {/* <Typography sx={{ mb: 4 }} fontWeight={700} color={text_color}>
                {t("attach.attach_succes_address")}: <Typography style={{ textDecoration: 'underline' }} onClick={onLkClick}>lk.whypn.com </Typography>
              </Typography> */}
              <Typography sx={{ mb: 4 }} fontWeight={700} color={text_color}>
                {t("attach.attach_succes_quest")}: <Typography style={{ textDecoration: 'underline' }} onClick={onHelpClick}>@whypnbot</Typography>
              </Typography>
            </Box>
          </Box>
      }


      {/*    <Box mt={2}>
        <Button style={{
            width: '100%',
            background:  bg_color,
            color: text_color,
            boxShadow: 'none',
            borderRadius: '12px'
        }} variant="contained" onClick={onCreateCodeClick}>Поддержать WhyPN</Button>
    </Box>
    <Box mt={2}>
        <Typography color={text_color}  style={{ width: '100%', fontSize: '12px' }} variant="body2">Средства с пожертвований не учитываются в балансе пользователя, а идут напрямую на поддержку проекта WhyPN. По всем вопросам о донатах обращайтесь в поддержку бота @whypnbot
        </Typography>
    </Box> */}
    </Box >
  );
}